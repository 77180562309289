import {
  ANSWER_UPDATED,
  ANSWER_VALIDATED
} from './conquest.actions';

export const initialState = {
  answer: {},
  isReady: false,
  validation: null
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case ANSWER_UPDATED: {
      return action.payload;
    }

    case ANSWER_VALIDATED: {
      return {
        ...state,
        validation: action.validation
      };
    }

    default:
      return state;
  }
};
