import _ from 'lodash';
import {
  SLIDE_LEFT_TOGGLED, WIDGET_SAVE, WIDGET_CANCEL, WIDGET_EDIT, PROVELET_DATA_ACQUIRED
} from './provelet-actions';
import { proveletStates } from '../../helpers/constants';

export const initialState = {
  isLeftSlided: false,
  isEditing: false,
  widgetEditor: null,
  flags: proveletStates.DEFAULT,
  data: [],
  audioData: {}
};

export default (state = initialState, action) => {
  const cloned = _.clone(state);
  switch (action.type) {
    case SLIDE_LEFT_TOGGLED: {
      cloned.isLeftSlided = action.isLeftSlided;
      return cloned;
    }
    case WIDGET_EDIT:
      cloned.isEditing = true;
      cloned.widgetEditor = action.widget;
      return cloned;
    case WIDGET_SAVE:
      cloned.data = action.payload;
      cloned.isEditing = false;
      cloned.widgetEditor = null;
      cloned.flags = action.flags;
      return cloned;
    case WIDGET_CANCEL:
      cloned.isEditing = false;
      cloned.widgetEditor = null;
      return cloned;
    case PROVELET_DATA_ACQUIRED:
      cloned.flags = action.flags;
      cloned.data = action.data;
      return cloned;
    default:
      return cloned;
  }
};
