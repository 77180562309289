import _ from 'lodash';

const totalLearnosityMarkingItems = (catPageRows) => {
  let count = 0;
  catPageRows.forEach((row) => {
    row.modules.forEach((module) => {
      const { value, score } = _.get(module, 'content.question.validation.valid_response') || { score: 0 };
      const isScored = _.get(module, 'content.question.validation.unscored', false) === false && score > 0;
      const hasValue = (_.get(value, 'length') || _.get(value, 'value.length')) > 0;

      if (isScored && hasValue) {
        count += 1;
      }
    });
  });
  return count;
};

export default totalLearnosityMarkingItems;
