/* global fetch */

import _ from 'lodash';
import { activateError } from '../error-handling/error-handling-actions';

export const DUCS_CONFIG_ACQUIRED = 'DUCS_CONFIG_ACQUIRED';
export const SCRIPT_LOAD_STATUS_SET = 'SCRIPT_LOAD_STATUS_SET';
export const LAYOUT_CONTENT_MERGED = 'LAYOUT_CONTENT_MERGED';
export const DUCS_PAGE_RENDERED = 'DUCS_PAGE_RENDERED';
export const RESET_DUCS = 'RESET_DUCS';

export const acquireDucsConfig = () => (dispatch) => {
  dispatch({
    type: DUCS_CONFIG_ACQUIRED,
    payload: {
      ducsLibraryUrl: window._env_.DUCSLibraryUrl,
      ducsConfiguration: JSON.parse(window._env_.DUCSConfiguration)
    }
  });
};

export const setScriptLoadStatus = isLoaded => ({
  type: SCRIPT_LOAD_STATUS_SET,
  payload: isLoaded
});

export const mergeLayoutContent = ({
  layoutPath,
  contentPath,
  data
}) => async (dispatch) => {
  try {
    const [layout, content] = await Promise.all([fetch(layoutPath), fetch(contentPath)]);
    const [layoutJSON, contentJSON] = await Promise.all([layout.json(), content.json()]);

    layoutJSON.rows.forEach((row) => {
      row.modules = row.modules.map((module) => {
        const finalModule = {
          ...module,
          ...contentJSON[module.id]
        };
        // TODO when there is current state
        if (_.has(data, 'finalModule.id')) {
          finalModule.content.question = data[finalModule.id];
        }

        return finalModule;
      });
    });

    dispatch({
      type: LAYOUT_CONTENT_MERGED,
      payload: { ...layoutJSON }
    });
  } catch (err) {
    dispatch(activateError(404));
  }
};

export const setDucsPageRender = status => ({
  type: DUCS_PAGE_RENDERED,
  status
});

export const resetActivity = () => ({
  type: RESET_DUCS
});
