import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans, t } from '@lingui/macro';
import { BtnText } from '../../components/buttons';
import css from './error-handling.scss';
import loginImage from '../../assets/images/error-boy.gif';


export default class ErrorPanel extends Component {
  constructor(props) {
    super(props);
    this.i18n = window.i18n;
  }

  handleSignIn = () => {
    window.location.href = this.props.loginUrl;
  };

  render() {
    return (
      <div className={css['error-handling']}>
        <h1 className={css['status-issue']}>
          {this.props.issue}
        </h1>
        <p className="icon">
          <img src={loginImage} alt={this.i18n._(t`Error Boy`)} height="200px" />
        </p>
        <p className={css.action}>
          {this.props.action}
        </p>
        <BtnText callBack={this.handleSignIn}>
          <Trans>Sign in</Trans>
        </BtnText>
      </div>
    );
  }
}

ErrorPanel.propTypes = {
  action: PropTypes.string,
  issue: PropTypes.string,
  loginUrl: PropTypes.string
};
