import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextToSpeech from '@3plearning/tts';
import classNames from 'classnames';
import css from './activity.scss';
import Ducs from '../ducs/ducs';
import { MODULE_NAME_ACTIVITY } from '../../helpers/constants';
import Triangle from '../../components/triangle/triangle';
import Resources from '../../helpers/resources';
import { BtnIcon } from '../../components/buttons';
import { getLocale } from '../../helpers/utils';
import { activateError } from '../error-handling/error-handling-actions';
import keyboardIcon from '../../assets/images/ico-keypad.svg';
import Conquest from '../conquest/conquest';
import {
  enableSpinner,
  ACTIVITY_RENDER_SPINNER
} from '../mle/mle-actions';

const Activity = () => {
  const dispatch = useDispatch();
  const { queryStringReducer } = useSelector(state => state);
  const initMleReducer = useSelector(state => state.initMleReducer);
  const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
  const { ducsPageRendered } = useSelector(state => state.ducsReducer);
  const { activityStatus } = useSelector(state => state.activityReducer);

  const [textSpeechConfig, setTextSpeechConfig] = useState(null);
  const [isKeyboardActive, setKeyboardActive] = useState(false);
  const [showKeyboardIcon, setShowKeyboardIcon] = useState(false);
  const [renderer, setRenderer] = useState(<div />);

  const getTextSpeechConfig = async () => {
    setTextSpeechConfig({
      authToken: Resources.env.authToken,
      serviceHostUrl: window._env_.TextToSpeechHostUrl,
      dataUrl: window._env_.TextToSpeechDataUrl,
      locale: getLocale() || 'en-AU',
      localisationCdnBaseUrl: window._env_.TextToSpeechLocalisationCdnBaseUrl,
      // eslint-disable-next-line max-len
      speechless: ['sq', 'hy', 'az', 'be', 'bs', 'ceb', 'co', 'hr', 'eo', 'et', 'fy', 'ka', 'ht', 'ha', 'haw', 'hmn', 'ig', 'ga', 'jv', 'kk', 'km', 'rw', 'ku', 'ky', 'lo', 'la', 'lb', 'mk', 'mg', 'mt', 'mi', 'mn', 'my', 'ne', 'ny', 'or', 'ps', 'fa', 'sm', 'gd', 'st', 'sn', 'sd', 'si', 'sl', 'so', 'su', 'sw', 'tg', 'tt', 'tk', 'ug', 'uz', 'xh', 'yi', 'yo', 'zu']
    });
  };

  const showKeyboardEl = (isShow) => {
    const el = window.document.querySelectorAll('.lrn-formula-keyboard');
    if (el) {
      if (isShow) {
        el.forEach(node => node.classList.remove('hidden'));
      } else {
        el.forEach(node => node.classList.add('hidden'));
      }
    }
  };

  const handleKeyboard = () => {
    if (!isKeyboardActive) {
      const el = window.document.querySelector('.js-ducs .lrn_focusable');
      if (el) {
        el.scrollIntoView();
        el.focus();
      }
      showKeyboardEl(true);
      setKeyboardActive(!isKeyboardActive);
    } else {
      showKeyboardEl(false);
      setKeyboardActive(!isKeyboardActive);
    }
  };

  useEffect(() => {
    dispatch(enableSpinner(ACTIVITY_RENDER_SPINNER));
  }, []);

  useEffect(() => {
    const type = {
      cat: <Ducs />,
      conquest: <Conquest />
    };

    if (!queryStringReducer.newContent) {
      setRenderer(<Ducs />);
    } else if (activityData) {
      setRenderer(type[activityData.meta.type]);
    }
  }, [activityData]);

  useEffect(() => {
    getTextSpeechConfig();
  }, []);

  useEffect(() => {
    showKeyboardEl(false);
    if (window.document.querySelector('.lrn-formula-keyboard')) {
      setShowKeyboardIcon(true);
    } else {
      setShowKeyboardIcon(false);
    }
  }, [ducsPageRendered]);

  return (
    <div className={css.activity}>
      {textSpeechConfig && ducsPageRendered &&
        <TextToSpeech
          textSpeechConfig={textSpeechConfig}
          reset={activityStatus}
          onError={() => { dispatch(activateError({ status: 500 })); }}
          className={css['audio-container']}
        />
      }
      {ducsPageRendered && showKeyboardIcon && <BtnIcon
        icon={keyboardIcon}
        className={classNames(css['keyboard-btn'], { [css.active]: isKeyboardActive })}
        callBack={handleKeyboard}
      />}
      <div className={css['inner-scroll']}>
        {renderer}
      </div>
      <Triangle
        className={css.tri}
        leftPx={'8'}
        rightPx={'0'}
        topPx={'10'}
        color={'white'}
      />
    </div>
  );
};

export default Activity;
