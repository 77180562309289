import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from '@lingui/macro';
import { constructModal } from '../modal/modal-actions';
import ErrorPanel from './error-panel';
import { activateError } from './error-handling-actions';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const ErrorHandling = () => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const status = useSelector(state => state.errorHandlingReducer.status);
  const action = i18n._(t`Please try logging in again`);
  const issue = i18n._(t`Something went wrong`);
  const [studentLoginPage, setStudentLoginPage] = useState(false);
  const [errorWindowLoaded, setErrorWindowLoaded] = useState(false);

  function getStudentLoginPageUrl() {
    setStudentLoginPage(window._env_.StudentLoginPage);
  }

  useEffect(() => {
    if (!studentLoginPage) {
      getStudentLoginPageUrl();
    }

    if (status && studentLoginPage && !errorWindowLoaded) {
      setErrorWindowLoaded(true);
      dispatch(constructModal({
        activate: true,
        content: <ErrorPanel
          action={action}
          issue={issue}
          loginUrl={studentLoginPage}
        />,
        canvasOnClick: false
      }));
      // Reset the reducer in case the next error is the same status
      dispatch(activateError(null));
    }
  }, [status, studentLoginPage]);

  return false;
};

export default ErrorHandling;
