/* eslint-disable jsx-a11y/media-has-caption */

import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { detectIe } from 'detect-msie';
import { Trans } from '@lingui/macro';
import iconPlay from '../../../assets/images/btn_audio_play.svg';
import iconPause from '../../../assets/images/btn_audio_pause.svg';
import iconRewind from '../../../assets/images/btn_audio_rewind.svg';
import { BtnIcon } from '../../../components/buttons';
import Player from './base-audio-controller';
import css from './audio-player.scss';

const sampleClassNames = {
  container: 'container',
  timeLine: 'timeline',
  playHead: 'audio-playhead',
  timeLabel: 'time-label'
};

export const AudioPlayer = ({ src, isEdit = false }) => {
  const isLeftSlided = useSelector(state => state.proveletReducer.isLeftSlided);

  const report = detectIe();

  return (
    <div className={css['audio-player']}>
      {
        report.isIe11 ? (
          <audio controls>
            <source src={src} type="audio/mpeg" />
          </audio>
        ) : (
          <Player src={src}>
            {({
              canPlay,
              playing,
              pause,
              play,
              rewind,
              setTimeToZero,
              movePlayHead,
              playHeadMarginLeft,
              setOnPlayHead,
              onPlayHead,
              timeLineRef,
              playHeadRef
            }) => {
              useEffect(() => {
                if (!playing) {
                  setTimeToZero();
                }
              }, [isLeftSlided]);

              return (
                <Fragment>
                  {canPlay ? (
                    <div className={sampleClassNames.container}>
                      {
                        playing ?
                          <BtnIcon
                            icon={iconPause}
                            callBack={pause}
                            className={css['btn-pause']}
                          /> :
                          <BtnIcon
                            icon={iconPlay}
                            callBack={play}
                            className={css['btn-play']}
                          />
                      }
                      <div
                        className={sampleClassNames.timeLine}
                        ref={timeLineRef}
                        onClick={movePlayHead}
                        onMouseMove={e => onPlayHead && movePlayHead(e)}
                        onMouseDown={() => setOnPlayHead(true)}
                        onMouseUp={() => setOnPlayHead(false)}
                        role="presentation"
                      >
                        <div
                          ref={playHeadRef}
                          className={sampleClassNames.playHead}
                          style={{ left: `${isNaN(playHeadMarginLeft) ? 0 : playHeadMarginLeft}%` }}
                        />
                      </div>
                      {!isEdit && <BtnIcon
                        icon={iconRewind}
                        callBack={rewind}
                        className={css['btn-rewind']}
                      />}
                    </div>
                  ) : (
                    <p className={css.txt}><Trans>No audio recorded</Trans></p>
                  )}
                </Fragment>
              );
            }}
          </Player>
        )
      }
    </div>
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string,
  isEdit: PropTypes.bool
};
