import React, { useEffect } from 'react';
import { Button, HStack, Img } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { useValidate } from './hooks/use-validate';
import Info from '../info/info';
import InfoFeedback from '../info-feedback/info-feedback';
import { validateAnswer } from '../conquest/conquest.actions';
import { disableSpinner, enableSpinner, recordTime, TIMESTAMP_CHECK } from '../mle/mle-actions';
import { ANSWER_VALIDATION_SPINNER } from './footer-qtc.actions';
import { useValidationCapsule } from './hooks/use-validation-capsule';
import { useFinish } from './hooks/use-finish';
import { useTeacherNotes } from './hooks/use-teacher-notes';
import iconTeacherNotes from '../../assets/images/icon_teacher_notes.svg';
import css from './footer-qtc.scss';

export const FooterQtc = () => {
  const dispatch = useDispatch();
  const { isReady, answer, validation } = useSelector(state => state.conquestReducer);
  const { mutate: validate, data, isSuccess, status } = useValidate();
  const { validationCapsule } = useValidationCapsule();
  const { handleFinish } = useFinish();
  const { checkIsTeacher, handleTeacherNotes } = useTeacherNotes();

  useEffect(() => {
    if (data) {
      dispatch(recordTime(TIMESTAMP_CHECK));
      dispatch(validateAnswer(data.data));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (status === 'pending') {
      dispatch(enableSpinner(ANSWER_VALIDATION_SPINNER));
    } else {
      dispatch(disableSpinner(ANSWER_VALIDATION_SPINNER));
    }
  }, [status]);

  return (
    <HStack className={css['footer-qtc']} >
      <HStack spacing="20px">
        {
          validation ?
            validationCapsule(validation.sectionResults, validation.isCorrect)
            : (
              <Button
                isDisabled={!isReady}
                onClick={() => validate(answer)}
                variant={'mle'}
              >
                <Trans>Check my answer</Trans>
              </Button>
            )
        }
        {
          checkIsTeacher &&
          <Button
            onClick={handleTeacherNotes}
            className={css['teacher-note-btn']}
          >
            <Img src={iconTeacherNotes} />
          </Button>
        }
        <HStack spacing="20px" className={css.dialogue}>
          <Info />
          <InfoFeedback />
        </HStack>
      </HStack>
      <Button
        variant={'mle'}
        isDisabled={!isSuccess}
        onClick={handleFinish}
      >
        <Trans>Finish</Trans>
      </Button>
    </HStack>
  );
};
