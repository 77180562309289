import _ from 'lodash';
import queryString from 'querystring';
import Resources from '../helpers/resources';
import { RESULT_TYPES } from './constants';
import * as TAG_MAPPINGS from './tag-mappings';

export function handleDefaults(defaultObj, dynamicObj) {
  for (const key in dynamicObj) {
    defaultObj[key] = dynamicObj[key];
  }
  return defaultObj;
}

export function log() { }

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
  try {
    const reader = new window.FileReader();
    reader.onload = () => {
      const dataUrl = reader.result;
      resolve(dataUrl.substr(dataUrl.indexOf(',') + 1));
    };
    reader.readAsDataURL(blob);
  } catch (err) {
    reject(err);
  }
});

export const getStrandsFromTags = (tagData = []) => {
  const strands = ['measurement', 'number&algebra', 'statistics&data', 'chance&probability', 'geometry'];
  const subStrands = ['meas', 'na', 'stat', 'cprob', 'geo'];
  const tags = Array.isArray(tagData) ? tagData : [];
  const strand = tags.find(tag => strands.includes(tag)) || '';
  const subStrand = tags.find(tag => subStrands.includes(tag.split('-')[0])) || '';
  const formattedStrand = _.get(TAG_MAPPINGS, `strands[${strand}].title`);
  const formattedSubStrand = TAG_MAPPINGS.substrands[subStrand.split('-')[1]];
  return {
    strand: formattedStrand,
    subStrand: formattedSubStrand
  };
};

export const getConfigItem = param => Resources.env.substituteConfigurationItems(param);

export const getActivityResult = (activityState) => {
  let totalCorrect = 0;
  let resultType = RESULT_TYPES.INCORRECT;
  const questionIds = Object.keys(activityState);
  const questionIdRegex = /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}|\d{14}/;
  const total = activityState.total;

  for (const questionId of questionIds) {
    if (
      questionIdRegex.test(questionId) &&
      !activityState[questionId].isNonMarking &&
      activityState[questionId].isCorrect
    ) {
      totalCorrect += 1;
    }
  }

  if (totalCorrect && (totalCorrect !== total)) {
    resultType = RESULT_TYPES.PARTIAL;
  }

  return {
    correct: totalCorrect,
    total,
    type: totalCorrect === total ? RESULT_TYPES.CORRECT : resultType
  };
};

export const getLocale = () => {
  const queryParams = window.location.search;
  const query = queryParams.replace('?', '');
  const params = queryString.parse(query);
  return params.locale;
};

export const removeLearnosityKeypad = () => {
  const learnosityKeypad = window.document.querySelector('.lrn_absolute_keyboard_parent');
  if (learnosityKeypad) {
    window.document.body.removeChild(learnosityKeypad);
  }
};

export const getModuleData = (modules, moduleName) =>
  _.find(_.get(modules, 'data'), d => _.get(d, 'meta.name') === moduleName);
