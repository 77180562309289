exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, "html{background:#ebebeb}.error-content---3LpIb{text-align:center;color:#464646;margin-top:6vh;opacity:0;animation:delay-visibility---knMnH .1s linear forwards 1s;font-family:Poppins,sans-serif}.error-content---3LpIb img{margin-right:20px;width:200px}.error-content---3LpIb h2{font-size:56px;margin-bottom:0;margin-top:10px}.error-content---3LpIb .error-caption---1RI1_{font-size:36px;font-weight:bold;margin-top:inherit}@keyframes delay-visibility---knMnH{0%{opacity:0}100%{opacity:1}}", ""]);

// exports
exports.locals = {
	"error-content": "error-content---3LpIb",
	"delay-visibility": "delay-visibility---knMnH",
	"error-caption": "error-caption---1RI1_"
};