import _ from 'lodash';
import {
  BTN_ACTIVATED,
  RESET_MENU,
  WIDGET_BTN,
  TEACHER_BTN
} from './widget-menu-actions';

export const initialState = {
  [WIDGET_BTN]: '',
  [TEACHER_BTN]: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BTN_ACTIVATED: {
      const cloned = _.clone(state);
      if (action.btn === WIDGET_BTN) {
        cloned[WIDGET_BTN] = 'expand';
        cloned[TEACHER_BTN] = 'contract';
      } else {
        cloned[WIDGET_BTN] = 'contract';
        cloned[TEACHER_BTN] = 'expand';
      }
      return cloned;
    }
    case RESET_MENU:
      return initialState;
    default:
      return state;
  }
};
