exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".audio-player---1HoeI{display:flex;align-items:center;justify-content:center;width:100%}.audio-player---1HoeI .btn-play---3LfQy,.audio-player---1HoeI .btn-pause---1Osj3,.audio-player---1HoeI .btn-rewind---1WQN_{padding:0}.audio-player---1HoeI .btn-rewind---1WQN_{margin-left:20px}.audio-player---1HoeI .txt---QJOGm{margin-bottom:20px}.container{display:flex;flex-grow:1;align-items:center}.timeline{width:100%;height:14px;background-color:#dbdbdb;border-radius:14px;margin-left:20px;position:relative}.audio-playhead{width:20px;height:20px;border-radius:22px;top:-3px;background-color:#fd7849;margin-left:0;position:absolute;transform:translate(-50%, 0)}.time-label{font-weight:600;font-size:14px;color:#e98074}", ""]);

// exports
exports.locals = {
	"audio-player": "audio-player---1HoeI",
	"btn-play": "btn-play---3LfQy",
	"btn-pause": "btn-pause---1Osj3",
	"btn-rewind": "btn-rewind---1WQN_",
	"txt": "txt---QJOGm"
};