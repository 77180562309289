exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".scratchpad-edit---1DL7E{overflow:hidden;min-height:400px}.scratchpad-edit---1DL7E .GeoGebraFrame .mowOpenMenuButton{display:none}.scratchpad-edit---1DL7E .GeoGebraFrame .undoRedoPanel.undoRedoPosition.undoRedoPositionMebis{left:0}.scratchpad-edit---1DL7E .GeoGebraFrame .toolbarMow .headerMow{background-color:#3477c7}.scratchpad-edit---1DL7E .GeoGebraFrame .toolbarMow .headerMow button[data-title=Media],.scratchpad-edit---1DL7E .GeoGebraFrame .toolbarMow .headerMow button[aria-label=Media],.scratchpad-edit---1DL7E .GeoGebraFrame .toolbarMow .headerMow button[data-test=mediaPanelButton]{display:none}.scratchpad-edit---1DL7E .GeoGebraFrame .toolbarMow .headerMow .content .center{left:calc(50% - 90px)}.scratchpad-edit---1DL7E .GeoGebraFrame .toolbarMow .headerMow .content .center.indicatorLeft .indicator{transform:translateX(50%) translateX(-42px)}.scratchpad-edit---1DL7E .GeoGebraFrame .toolbarMow .headerMow .content .center.indicatorCenter .indicator{transform:translateX(50%) translateX(6px)}.scratchpad-edit---1DL7E .GeoGebraFrame .flatButton:focus{outline:0}.scratchpad-edit---1DL7E .GeoGebraFrame .gwt-MenuBar.gwt-MenuBar-vertical .gwt-MenuItem.listMenuItem:nth-child(2){display:none}", ""]);

// exports
exports.locals = {
	"scratchpad-edit": "scratchpad-edit---1DL7E"
};