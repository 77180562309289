exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".workbook---1TK1N{display:flex;justify-content:center;padding-bottom:15px}.workbook---1TK1N .icon---2q-ZU{margin-left:-52px;pointer-events:none;margin-right:10px}.workbook---1TK1N .text---2tDKj{color:#fd7849;font-size:24px;font-weight:bold}", ""]);

// exports
exports.locals = {
	"workbook": "workbook---1TK1N",
	"icon": "icon---2q-ZU",
	"text": "text---2tDKj"
};