exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".record-audio-edit---_-MbM{padding:20px 20px 10px}.record-audio-edit---_-MbM .re-record---KhQDf{height:0}.record-audio-edit---_-MbM .recorder-control---1gCEa{width:100%;display:flex;align-items:center;justify-content:center}.record-audio-edit---_-MbM .recorder-control---1gCEa .container---3iZue{display:flex;align-items:center;justify-content:center;flex-grow:1}.record-audio-edit---_-MbM .recorder-control---1gCEa .container---3iZue .slider---3E_ue{margin-left:20px}.record-audio-edit---_-MbM .recorder-control---1gCEa .start-record---3D0nT{display:flex;flex-direction:column;align-items:center}.record-audio-edit---_-MbM .recorder-control---1gCEa .start-record---3D0nT .txt---2QlSe{padding-top:5px;color:#545454}.record-audio-edit---_-MbM .recorder-control---1gCEa .btn-rerender---17yYk{padding:0 20px;min-width:60px;font-size:13px;height:30px;background-color:#3477c7;transform:translate(0, 100%)}.record-audio-edit---_-MbM .recorder-control---1gCEa .btn-rerender---17yYk:hover{background-color:#fd7849}", ""]);

// exports
exports.locals = {
	"record-audio-edit": "record-audio-edit---_-MbM",
	"re-record": "re-record---KhQDf",
	"recorder-control": "recorder-control---1gCEa",
	"container": "container---3iZue",
	"slider": "slider---3E_ue",
	"start-record": "start-record---3D0nT",
	"txt": "txt---2QlSe",
	"btn-rerender": "btn-rerender---17yYk"
};