exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".widget-editor---368lh{display:flex;justify-content:center;margin-left:50px}.widget-editor---368lh .widget---328R4{display:flex;flex-direction:column;flex-grow:1;background-color:#eaeaea;border-radius:6px;border:2px solid #eaeaea;border-bottom-right-radius:0;max-width:100%}.widget-editor---368lh .widget---328R4 .loading-container---1LvFA{width:100%;display:flex;justify-content:center;padding:20px;align-items:center}.widget-editor---368lh .widget---328R4 .footer---1QuBb{justify-content:space-between;display:flex;padding:5px 10px}", ""]);

// exports
exports.locals = {
	"widget-editor": "widget-editor---368lh",
	"widget": "widget---328R4",
	"loading-container": "loading-container---1LvFA",
	"footer": "footer---1QuBb"
};