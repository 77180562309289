import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import css from './info-icon.scss';
import { BtnIcon } from '../../components/buttons';
import Tip from '../../components/tip/tip';

const InfoIcon = ({ className, infoIconData: { icon, paragraph, showTip, ignoredRef, onClick = () => { } } }) => {
  const wrapperRef = useRef(null);

  const handleClick = (event, btn) => {
    if (ignoredRef && ignoredRef.current && ignoredRef.current.contains(event.target)) {
      return;
    }
    if (btn) {
      if (window.document.activeElement) {
        // prevent button from taking focus so it cannot be triggered by Enter or Space bar
        window.document.activeElement.blur();
      }
      onClick(event);
    } else if (!btn && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      onClick();
    }
  };
  useEffect(() => {
    // use capture to ensure check for ignoredRef is done before nodes are removed from DOM
    window.document.addEventListener('click', handleClick, true);
    window.document.addEventListener('touchend', handleClick, true);
    return () => {
      window.document.removeEventListener('click', handleClick, true);
      window.document.removeEventListener('touchend', handleClick, true);
    };
  });

  return (
    <div className={classNames(css['info-content'], className)} ref={wrapperRef}>
      <BtnIcon
        icon={icon}
        className={classNames(css['btn-icon'], 'info-icon-btn')}
        callBack={e => handleClick(e, true)}
      />
      {showTip && <Tip className={css.tip} paragraph={paragraph} />}
    </div>
  );
};

InfoIcon.propTypes = {
  className: PropTypes.string,
  infoIconData: PropTypes.object
};

export default InfoIcon;
