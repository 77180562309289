exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".browser-support---3rLEk{min-width:400px;padding:20px;font-weight:bold;font-size:23px;text-align:center;color:#00008b;align-items:center;position:relative;background-color:#e8e7e8;padding-bottom:20px;margin-bottom:-20px}.browser-support---3rLEk .icon---2QliN{margin:20px 0}.browser-support---3rLEk .status-issue---VAIC8{font-weight:bold;font-size:30px;color:#1eb4e6;margin-bottom:20px}.browser-support---3rLEk .action---3g_I0{margin-bottom:20px}", ""]);

// exports
exports.locals = {
	"browser-support": "browser-support---3rLEk",
	"icon": "icon---2QliN",
	"status-issue": "status-issue---VAIC8",
	"action": "action---3g_I0"
};