import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constructModal, activateModal } from '../modal/modal-actions';
import { Detector } from './detect-offline';
import { NoInternet } from '../../components/errors';

const InternetConnectivity = () => {
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    if (isConnected) {
      dispatch(activateModal(false));
    } else {
      dispatch(constructModal({
        activate: true,
        content: <NoInternet />,
        canvasOnClick: false
      }));
    }
  }, [isConnected]);

  return (
    <Detector
      render={({ online }) => {
        if (online) {
          setIsConnected(true);
        } else {
          setIsConnected(false);
        }
        return null;
      }}
      polling={{
        url: window._env_.InternetConnectivityPollingUrl
      }}
    />
  );
};

export default InternetConnectivity;
