exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".no-internet---2tCqs{max-width:400px;width:100%;padding:40px 20px}.no-internet---2tCqs>*{text-align:center;margin-bottom:20px}.no-internet---2tCqs .head---2MKrm{color:#3477c7;font-size:32px}.no-internet---2tCqs .subhead---ymKp1{font-size:22px;font-weight:bold}.no-internet---2tCqs .para---IdjEb{font-size:18px}", ""]);

// exports
exports.locals = {
	"no-internet": "no-internet---2tCqs",
	"head": "head---2MKrm",
	"subhead": "subhead---ymKp1",
	"para": "para---IdjEb"
};