import React from 'react';
import PropTypes from 'prop-types';
import css from './widget-menu.scss';
import AddWidget from '../add-widget/add-widget';
import AskTeacher from '../ask-teacher/ask-teacher';

const WidgetMenu = ({ hideAskTeacher }) => (
  <div className={css['widget-menu']}>
    <AddWidget />
    {!hideAskTeacher && <AskTeacher />}
  </div>
);

WidgetMenu.propTypes = {
  hideAskTeacher: PropTypes.bool
};

WidgetMenu.defaultProps = {
  hideAskTeacher: false
};

export default WidgetMenu;
