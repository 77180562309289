import React, { useEffect, useState } from 'react';
import TinyTimer from 'tiny-timer';
import PropTypes from 'prop-types';
import css from './slider.scss';

export const Slider = ({ duration = 10, start = true, stop = false, callBack, className }) => {
  const timer = new TinyTimer({ interval: 10 });
  const durationMs = duration * 1000;

  const [ticker, setTicker] = useState(0);

  useEffect(() => {
    timer.on('tick', ms => setTicker(((durationMs - ms) / durationMs) * 100));
    timer.on('done', () => callBack());

    if (start) {
      timer.start(durationMs);
    }
    if (stop) {
      timer.stop();
    }

    return () => {
      timer.stop();
    };
  }, [start, stop]);

  return (
    <div className={`${css.slider} ${className || ''}`}>
      <div
        className={css['audio-playhead']}
        style={{
          left: `Calc(${ticker}% - 10px)`
        }}
      />
    </div>
  );
};

Slider.propTypes = {
  duration: PropTypes.number,
  start: PropTypes.bool,
  stop: PropTypes.bool,
  callBack: PropTypes.func.isRequired,
  className: PropTypes.string
};
