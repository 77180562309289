import { userRoles } from '../../helpers/constants';

export const SET_USER_ROLE = 'SET_USER_ROLE';
export const SET_USER_TYPE = 'SET_USER_TYPE';

export const setUserRole = (userRole = userRoles.DEFAULT) => ({
  type: SET_USER_ROLE,
  userRole
});

export const setUserType = userType => ({
  type: SET_USER_TYPE,
  userType
});
