import React from 'react';
import PropTypes from 'prop-types';
import { DateFormat } from '@lingui/macro';
import css from './date-header.scss';

const DateHeader = ({ date, prevDate }) => {
  const showDate = () => (
    !prevDate
    || prevDate.getDate() !== date.getDate()
    || prevDate.getDay() !== date.getDay()
    || prevDate.getFullYear() !== date.getFullYear()
  );

  const result = (showDate() &&
    <div className={css.header}>
      <hr className={css.hr} />
      <span className={css.date}>
        <span>
          <DateFormat value={date} format={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />
        </span>
      </span>
      <hr className={css.hr} />
    </div>
  );

  return result;
};

DateHeader.propTypes = {
  date: PropTypes.object,
  prevDate: PropTypes.object
};

export default DateHeader;
