exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".header---1eM6M{display:flex;justify-content:center;margin:15px 0;font-size:small}.header---1eM6M:first-child{margin-top:0}.header---1eM6M .hr---mDbo6{border:.5px solid gray;flex-grow:1}.header---1eM6M .date---321im{color:gray;padding:0 30px}", ""]);

// exports
exports.locals = {
	"header": "header---1eM6M",
	"hr": "hr---mDbo6",
	"date": "date---321im"
};