import React, { useEffect, useState, Fragment, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGa from 'react-ga';
import _ from 'lodash';
import { KeypadContext } from '@3plearning/question-components-library';
import {
  acquireAssignmentStatus,
  acquireInitMleData,
  acquireQueryString,
  recordTime,
  TIMESTAMP_ENTER,
  queryString,
  registerDefaultAssign
} from './mle-actions';
import css from './mle.scss';
import Activity from '../activity/activity';
import Provelet from '../provelet/provelet';
import Header from '../header/header';
import { FooterQtc } from '../footer-qtc/footer-qtc';
import Footer from '../footer/footer';
import { getStrandsFromTags } from '../../helpers/utils';
import { setUserType } from '../user/user-actions';
import { MODULE_NAME_ACTIVITY, MODULE_NAME_PROVELET, userTypes } from '../../helpers/constants';
import InternetConnectivity from '../internet-connectivity/internet-connectivity';
import Spinner from '../../components/spinner/spinner';
import ErrorHandling from '../error-handling/error-handling';
import Modal from '../modal/modal';
import DetectDevtools from '../devtools-detect/devtools-detect';
import DevToolsMessage from '../devtools-detect/devtools-message';
import Resources from '../../helpers/resources';

const Mle = () => {
  const dispatch = useDispatch();
  const { initMleReducer, spinnerReducer, queryStringReducer } = useSelector(state => state);
  const { isDevToolsOpen } = useSelector(state => state.detectDevToolsReducer);

  const activityMetaData = (initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY) || {}).meta;
  const proveletMetaData = (initMleReducer.find(item => item.meta.name === MODULE_NAME_PROVELET) || {}).meta;
  const { type, tags, title, user: { locale } = {} } = activityMetaData || {};
  const { strand, subStrand } = getStrandsFromTags(tags, locale);
  const {
    questionBodyRef
  } = useContext(KeypadContext);

  const [googleAnalyticsId, setGoogleAnalyticsId] = useState(false);

  const getGoogleAnalyticsId = async () => {
    try {
      const id = await Resources.env.substituteConfigurationItems('@GoogleTagManagerID');
      setGoogleAnalyticsId(id);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Error acquiring Google Tag manager ID!');
    }
  };

  useEffect(() => {
    if (googleAnalyticsId) {
      ReactGa.initialize(googleAnalyticsId);
    }
  }, [googleAnalyticsId]);

  useEffect(() => {
    try {
      if (_.has(proveletMetaData, 'user')) {
        dispatch(setUserType(proveletMetaData.user.schoolId > 0 ? userTypes.SCHOOLSTUDENT : userTypes.HOMESTUDENT));
      }
    } catch (e) {
      console.log(e);
    }
  }, [proveletMetaData]);

  useEffect(() => {
    if (queryString().defaultAssignTeacherId) {
      dispatch(registerDefaultAssign({
        teacherUserProfileId: queryString().defaultAssignTeacherId,
        girlId: queryString().girlId
      }));
    }
    dispatch(acquireAssignmentStatus());
    dispatch(acquireQueryString());
    dispatch(acquireInitMleData());
    dispatch(recordTime(TIMESTAMP_ENTER));
    getGoogleAnalyticsId();
  }, []);

  return (
    <Fragment>
      <DetectDevtools />
      <ErrorHandling />
      <Modal />
      <InternetConnectivity />
      {
        isDevToolsOpen ?
          <DevToolsMessage /> :
          <div className={css.mle}>
            <Header category={strand ? `${strand}${subStrand ? `: ${subStrand}` : ''}` : strand} title={title} />
            <div className={css.content} ref={questionBodyRef}>
              <Activity />
              <Provelet />
            </div>
            {
              type && type === 'conquest' && queryStringReducer.newContent ? <FooterQtc /> : <Footer />
            }
            <Spinner show={spinnerReducer.length > 0} />
          </div>
      }
    </Fragment>
  );
};

export default Mle;
