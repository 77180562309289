import React from 'react';
import PropTypes from 'prop-types';
import { t, Trans } from '@lingui/macro';
import { BtnText } from '../../components/buttons';
import css from './browser-support-modal.scss';
import loginImage from '../../assets/images/error-boy.gif';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const BrowserSupportModal = ({ issue, action, handleOk }) => {
  const { i18n } = useLocalisation();
  return (
    <div className={css['browser-support']}>
      <h1 className={css['status-issue']}>
        {issue}
      </h1>
      <p className="icon">
        <img src={loginImage} alt={i18n._(t`Error boy`)} height="200px" />
      </p>
      {
        action &&
        <p className={css.action}>
          {action}
        </p>
      }
      <BtnText callBack={handleOk}>
        <Trans>OK</Trans>
      </BtnText>
    </div>
  );
};

BrowserSupportModal.propTypes = {
  issue: PropTypes.string.isRequired,
  action: PropTypes.string,
  handleOk: PropTypes.func.isRequired
};

export default BrowserSupportModal;
