import moment from 'moment';
import { timestamps } from '../../helpers/constants';

export const getStartTimeOnline = () => moment.utc().toISOString();

export const getTimeOnline = (event, startTime) => {
  const correctEvent = event === timestamps.STUDENT_LEFT ||
    event === timestamps.STUDENT_COMPLETED_ACTIVITY;

  return correctEvent ? {
    timeOnlineSec: moment.utc().diff(moment.utc(startTime), 'seconds')
  } : null;
};
