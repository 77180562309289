import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import css from './scan.scss';
import { constructModal, activateModal } from '../../modal/modal-actions';
import ImageModal from '../../../components/image-modal/image-modal';
import API from '../../../helpers/api';

const ScanView = ({ data }) => {
  const [imageUrl, setImageUrl] = useState(data.imageUrl);
  const { girlId, userId, assignmentId } = useSelector(state => state.queryStringReducer);
  const dispatch = useDispatch();
  const getImageUrl = async () => {
    setImageUrl(
      await API.getResourceUrl({ userId, girlId, guid: data.imageId, contentType: 'image/png', assignmentId })
    );
  };

  useEffect(() => {
    if (data.imageId) {
      getImageUrl();
    }

    return () => {
      window.URL.revokeObjectURL(imageUrl);
    };
  }, []);

  const expandImage = () => {
    dispatch(constructModal({
      activate: true,
      canvasOnClick: false,
      content: <ImageModal
        url={imageUrl}
        handleCancel={() => { dispatch(activateModal(false)); }}
      />
    }));
  };

  return (
    <div
      className={css['scan-view']}
      onClick={expandImage}
      role="presentation"
      style={{
        backgroundImage: `url(${imageUrl})`
      }}
    />
  );
};

ScanView.propTypes = {
  data: PropTypes.object
};

export default ScanView;
