import React from 'react';
import { Trans, t } from '@lingui/macro';
import errorBoy from '../../assets/images/error-boy.gif';
import css from './devtools-message.scss';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const DevToolsMessage = () => {
  const { i18n } = useLocalisation();
  return (
    <section className={css['error-content']}>
      <img className={css['error-boy']} src={errorBoy} alt={i18n._(t`Error boy`)} />
      <h2><Trans>Oops, where did my activity go!</Trans></h2>
      <h5 className={css['error-caption']}>
        <Trans>To continue with this activity, please close the debugger and refresh your browser.</Trans>
      </h5>
    </section>
  );
};

export default DevToolsMessage;
