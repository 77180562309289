import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import css from './btn-radio-icon.scss';

const STATE_DEFAULT = 0;
const STATE_SELECTED = 1;
const STATE_DISABLED = 2;

export const BtnRadioIcon = forwardRef((props, externalRef) => {
  const icons = [props.iconDefault, props.iconSelected, props.iconDisabled];
  const state =
    // eslint-disable-next-line no-nested-ternary
    props.isDisabled ? STATE_DISABLED :
      props.isSelected ? STATE_SELECTED :
        STATE_DEFAULT;

  const onClick = () => {
    if (props.onClick) {
      props.onClick(externalRef);
    }
  };

  return (
    <button
      title={props.title}
      className={`${css.button} ${props.className}`}
      disabled={props.isDisabled}
      onClick={onClick}
    >
      <InlineSVG className={css.icon} src={icons[state]} />
    </button>
  );
});

BtnRadioIcon.propTypes = {
  iconDefault: PropTypes.string,
  iconSelected: PropTypes.string,
  iconDisabled: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  title: PropTypes.string
};
