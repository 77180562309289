import React from 'react';
import PropTypes from 'prop-types';
import css from './text-view.scss';

const TextView = ({ data }) => (
  <pre className={css['text-view']}>{data.text}</pre>
);

TextView.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string
  })
};

export default TextView;
