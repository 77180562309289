import React from 'react';
import PropTypes from 'prop-types';
import css from './btn-audio.scss';

export const BtnAudio = ({ className, callBack, disabled, isRecord }) => (
  <button
    className={`${css['btn-audio']} ${isRecord ? css.record : ''} ${className || ''}`}
    onClick={callBack}
    disabled={disabled}
  >
    <div
      className={css.inner}
    />
  </button>
);

BtnAudio.propTypes = {
  className: PropTypes.string,
  callBack: PropTypes.func,
  disabled: PropTypes.bool,
  isRecord: PropTypes.bool
};
