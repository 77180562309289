exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".dialogue---19iU- div{line-height:15px}.footer-qtc---1OlZO{padding:0 20px;height:80px;align-items:flex-start !important;justify-content:space-between;z-index:10}.footer-qtc---1OlZO .teacher-note-btn---YC677{background-color:#3477c7;height:50px;width:50px;overflow:hidden}.footer-qtc---1OlZO .teacher-note-btn---YC677 img{width:30px;position:absolute}.footer-qtc---1OlZO .teacher-note-btn---YC677:hover{background-color:#fd7849}", ""]);

// exports
exports.locals = {
	"dialogue": "dialogue---19iU-",
	"footer-qtc": "footer-qtc---1OlZO",
	"teacher-note-btn": "teacher-note-btn---YC677"
};