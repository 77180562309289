import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './selectbox.scss';

const Selectbox = ({ options, value, placeholder, onSelect, className }) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectBoxRef = useRef(null);

  const hideOptions = (event) => {
    if (selectBoxRef.current.contains(event.target)) return;
    setShowOptions(false);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  useEffect(() => {
    window.document.addEventListener('click', hideOptions, false);
    selectBoxRef.current.addEventListener('click', toggleOptions, false);
    return () => {
      window.document.removeEventListener('click', hideOptions);
      try {
        selectBoxRef.current.removeEventListener('click', toggleOptions);
      } catch (err) {
        console.error(err);
      }
    };
  }, [showOptions]);

  const selectedOption = options.find(option => option.value === value);

  return (
    <div className={`${css.selectbox} ${className}`} ref={selectBoxRef}>
      <button
        className={css['selected-option']}
      >
        {selectedOption ? selectedOption.label : placeholder}
      </button>
      <button className={css['btn-toggle-options']}>
        <div className={`${css.chevron} ${css[showOptions ? 'chevron-up' : 'chevron-down']}`} />
      </button>
      <div className={classNames(css['selectbox-options'], { [css.hide]: !showOptions })}>
        {
          options.map(option => (
            <button
              className={css['selectbox-option']}
              onClick={() => { onSelect(option.value); }}
              key={`select-option-${option.value}`}
            >
              {option.label}
            </button>
          ))
        }
      </div>
    </div>
  );
};

Selectbox.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string
};

export default Selectbox;
