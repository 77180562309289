import React from 'react';
import { useSelector } from 'react-redux';
import InlineSVG from 'react-inlinesvg';
import _ from 'lodash';
import { t } from '@lingui/macro';
import userIcon from '../../assets/images/icon_user.svg';
import previewIcon from '../../assets/images/icon_preview.svg';
import css from './profile.scss';
import { MODULE_NAME_ACTIVITY } from '../../helpers/constants';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const Profile = () => {
  const { i18n } = useLocalisation();
  const {
    queryStringReducer,
    initMleReducer
  } = useSelector(state => state);

  const { isPreview } = queryStringReducer;
  const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);

  let userName = i18n._(t`PREVIEW MODE`);
  if (!isPreview) {
    userName = _.get(activityData, 'meta.user.firstName', '');
  }
  const iconType = isPreview ? previewIcon : userIcon;

  return (
    <div className={css.profile}>
      <InlineSVG src={iconType} className={css.icon} />
      <p data-testid="user-name" className={css.text}>{userName}</p>
    </div>

  );
};

export default Profile;
