exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".header---1xVMn{height:100px;color:#fff;padding:14px 20px;background-color:#3477c7;display:flex;position:relative;justify-content:space-between}.header---1xVMn .left---oYOSq{display:flex;flex-direction:row;overflow:hidden}.header---1xVMn .left---oYOSq .back---3VQFE{padding:0;margin-right:15px;height:52px;width:52px}.header---1xVMn .left---oYOSq .logo---2NVZB{height:calc(2*100px/3);width:auto;transform:scale(0.65) translate(-25%, -25%)}.header---1xVMn .left---oYOSq .text---2zzZb{margin-left:-40px;overflow:hidden}.header---1xVMn .left---oYOSq .text---2zzZb .title---1r0lr{font-size:24px;font-weight:normal}.header---1xVMn .left---oYOSq .text---2zzZb>*{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.header---1xVMn .right---3VRsP{padding-top:15px;display:flex;flex-direction:row;justify-content:flex-end;overflow:hidden;min-width:200px;margin-left:20px;margin-right:17px}", ""]);

// exports
exports.locals = {
	"header": "header---1xVMn",
	"left": "left---oYOSq",
	"back": "back---3VQFE",
	"logo": "logo---2NVZB",
	"text": "text---2zzZb",
	"title": "title---1r0lr",
	"right": "right---3VRsP"
};