import ReactGa from 'react-ga';
import Resources from '../../helpers/resources';
import { userRoles, MODULE_NAME_PROVELET, MODULE_TYPE_DEFAULT } from '../../helpers/constants';
import { activateError } from '../error-handling/error-handling-actions';
import { TEACHER_BTN } from '../widget-menu/widget-menu-actions';
import { ACTIONS } from '../../helpers/get-next-flags/get-next-flags';
import { convertBlobToBase64 } from '../../helpers/utils';
import { enableLoadingBlanket } from '../widget-editor/widget-editor-actions';
import API from '../../helpers/api';

export const SLIDE_LEFT_TOGGLED = 'SLIDE_LEFT_TOGGLED';
export const WIDGET_EDIT = 'WIDGET_EDIT';
export const WIDGET_SAVE = 'WIDGET_SAVE';
export const WIDGET_CANCEL = 'WIDGET_CANCEL';
export const PROVELET_DATA_ACQUIRED = 'PROVELET_DATA_ACQUIRED';

export const toggleSlideLeft = isLeftSlided => ({
  type: SLIDE_LEFT_TOGGLED,
  isLeftSlided
});

export const widgetEdit = widget => (dispatch) => {
  dispatch(enableLoadingBlanket(false));
  dispatch({
    type: WIDGET_EDIT,
    widget
  });
};

export const widgetSave = content => async (dispatch, getState) => {
  try {
    const {
      proveletReducer: { data: proveletData, flags, widgetEditor: widgetName },
      queryStringReducer: { userId, girlId, isPreview, assignmentId },
      userReducer: { userRole },
      widgetMenuReducer
    } = getState();

    dispatch(enableLoadingBlanket(true));

    if (widgetName === 'Scan' && !isPreview) {
      content.imageUrl = '';
      try {
        const saveResource = userId ? Resources.saveTeacherResource : Resources.saveStudentResource;
        const params = {};

        if (userId) {
          params.userId = userId;
        }

        const resourceData = await saveResource
          .post(params)
          .withBody({
            content: content.canvas.split(',')[1],
            assignmentId,
            resourceId: girlId
          })
          .send();

        if (resourceData.status === 200 || resourceData.status === 204) {
          content.imageId = resourceData.data.id;
        }
      } catch (err) {
        content.imageId = '';
      }
    }

    if (widgetName === 'RecordAudio' && !isPreview) {
      content.audioUrl = '';
      try {
        const audioContent = await convertBlobToBase64(content.audioBlob);
        const saveResource = userId ? Resources.saveTeacherResource : Resources.saveStudentResource;
        const params = {};

        if (userId) {
          params.userId = userId;
        }

        const resourceData = await saveResource
          .post(params)
          .withBody({
            content: audioContent,
            assignmentId,
            resourceId: girlId
          })
          .send();

        ReactGa.event({
          category: 'Button - Save Audio Record',
          action: 'Save Audio Record',
          label: 'PSR Teacher/Student Interaction'
        });

        if (resourceData.status === 200 || resourceData.status === 204) {
          content.audioId = resourceData.data.id;
        }
      } catch (err) {
        content.audioId = '';
      }
    }

    const isQuestionExpanded = widgetMenuReducer[TEACHER_BTN] === 'expand';
    const payload = {
      widgetName,
      widgetData: {
        ...content,
        renderAs: {
          user: userRole,
          isQuestion: isQuestionExpanded && userRole === userRoles.STUDENT,
          isReply: isQuestionExpanded && userRole === userRoles.TEACHER
        }
      },
      createdUTC: (new Date()).toISOString()
    };

    if (isPreview) {
      dispatch({
        type: WIDGET_SAVE,
        payload: [
          ...proveletData,
          payload
        ]
      });
      dispatch(enableLoadingBlanket(false));
      return;
    }

    const response = await API.saveModules({
      userId,
      moduleName: MODULE_NAME_PROVELET,
      moduleType: MODULE_TYPE_DEFAULT,
      girlId,
      flags,
      payload,
      user: userRole,
      action: isQuestionExpanded ? ACTIONS.QUESTION : ACTIONS.WIDGET,
      assignmentId
    });
    if (response.status === 200 || response.status === 204) {
      dispatch({
        type: WIDGET_SAVE,
        payload: response.data.data,
        flags: response.data.meta.flags
      });
    } else {
      dispatch(activateError(response.status));
    }
  } catch (err) {
    dispatch(activateError(err));
  }
};

export const widgetCancel = () => ({
  type: WIDGET_CANCEL
});
