import React from 'react';
import InlineSVG from 'react-inlinesvg';
import { Trans } from '@lingui/macro';
import iconWorkbook from '../../assets/images/icon_workbook.svg';
import css from './my-workbook.scss';

const MyWorkbook = () => (
  <div className={css.workbook}>
    <InlineSVG src={iconWorkbook} className={css.icon} />
    <p className={css.text}><Trans>My Workbook</Trans></p>
  </div>
);

export default MyWorkbook;
