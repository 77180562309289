/* eslint-disable no-bitwise */
import { proveletStates, userRoles } from '../constants';

const { PROVELET_CHANGED, QUESTION_ASKED, TEACHER_REPLIED, TEACHER_REVIEWED } = proveletStates;
const { STUDENT, TEACHER } = userRoles;

const VIEW = 'view';
const WIDGET = 'widget';
const QUESTION = 'question';

const hasFlag = (flags, flag) => (flags & flag) === flag;
const setFlag = (flags, action) => {
  const newFlags = flags | action;
  return newFlags;
};
const removeFlag = (flags, action) => {
  const newFlags = flags & ~action;
  return newFlags;
};

const getNextStudentFlags = ({ action, flags }) => {
  let result = flags;
  if (action === WIDGET || action === QUESTION) {
    result = removeFlag(result, TEACHER_REVIEWED);
    result = setFlag(result, PROVELET_CHANGED);
  }
  if (action === QUESTION) {
    result = setFlag(result, QUESTION_ASKED);
  }
  return result;
};

const getNextTeacherFlags = ({ action, flags }) => {
  let result = flags;
  if (hasFlag(result, PROVELET_CHANGED)) {
    result = removeFlag(result, PROVELET_CHANGED);
    result = setFlag(result, TEACHER_REVIEWED);
  }
  if (action === WIDGET || action === QUESTION) {
    result = setFlag(result, TEACHER_REPLIED);
  }
  if (action === QUESTION) {
    result = removeFlag(result, QUESTION_ASKED);
  }
  return result;
};

export const getNextFlags = ({ user, action, flags }) => {
  if (user === STUDENT) {
    return getNextStudentFlags({ action, flags });
  } else if (user === TEACHER) {
    return getNextTeacherFlags({ action, flags });
  }
  return flags;
};

export const ACTIONS = { VIEW, WIDGET, QUESTION };
