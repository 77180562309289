/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

const Player = ({ src, children }) => {
  const [onPlayHead, setOnPlayHead] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [canPlay, setCanPlay] = useState(true); // temporary fix for audio not playing in ipad safari
  const [playHeadMarginLeft, setPlayHeadMarginLeft] = useState(0);
  const [playedTime, setPlayedTime] = useState(0);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = React.createRef();
  const timeLineRef = React.createRef();
  const playHeadRef = React.createRef();

  const getElementPosition = element => element && element.getBoundingClientRect().left;
  const getAudioCurrentTime = () => audioRef.current.currentTime;
  const getTimelineWidth = () => (timeLineRef.current && playHeadRef.current ?
    timeLineRef.current.offsetWidth - playHeadRef.current.offsetWidth :
    0);

  const movePlayHead = (e) => {
    const timelineWidth = getTimelineWidth();

    const updatedMarginLeft =
      e.clientX - getElementPosition(timeLineRef.current);

    setAudioCurrentTime(duration * (updatedMarginLeft / timelineWidth));

    let playPercent = 100 * (audioCurrentTime / duration);
    if (playPercent < 0) {
      playPercent = 0;
    }
    if (playPercent > 100) {
      playPercent = 100;
    }

    setPlayHeadMarginLeft(playPercent);
  };

  useEffect(() => {
    audioRef.current.currentTime = audioCurrentTime;
  }, [audioCurrentTime]);

  const play = () => audioRef.current.play();
  const pause = () => audioRef.current.pause();
  const rewind = () => {
    pause();
    audioRef.current.currentTime = 0;
    play();
  };
  const setTimeToZero = () => {
    pause();
    audioRef.current.currentTime = 0;
  };

  return (
    <Fragment>
      <audio
        data-testid="audio-element"
        ref={audioRef}
        onTimeUpdate={() => {
          const currentTime = getAudioCurrentTime();
          const playPercent = 100 * (currentTime / duration);
          setPlayHeadMarginLeft(playPercent);
          setPlayedTime(currentTime);
        }}
        onEnded={() => setPlaying(false)}
        onPause={() => setPlaying(false)}
        onPlay={() => setPlaying(true)}
        onCanPlayThrough={() => {
          setDuration(audioRef.current.duration);
          setCanPlay(true);
        }}
      >
        <source src={src} type="audio/mpeg" />
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      {children({
        canPlay,
        playing,
        pause,
        play,
        rewind,
        setTimeToZero,
        movePlayHead,
        playHeadMarginLeft,
        setOnPlayHead,
        onPlayHead,
        duration,
        timeLineRef,
        playHeadRef,
        playedTime
      })}
    </Fragment>
  );
};

Player.propTypes = {
  src: PropTypes.string,
  children: PropTypes.func
};

export default Player;
