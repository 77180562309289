exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".mle---2xxnP{display:flex;flex-direction:column;height:100%;background-color:#00abe8;width:100vw;overflow:hidden}.mle---2xxnP .content---34ZzN{display:flex;position:relative;top:-20px;height:calc(100vh - 160px)}.version-number{position:absolute;bottom:5px;margin-left:10px;color:#888;font-size:12px}", ""]);

// exports
exports.locals = {
	"mle": "mle---2xxnP",
	"content": "content---34ZzN"
};