import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import UserFeedback from '@3plearning/user-feedback';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import Resources from '../../helpers/resources';
import css from './info-feedback.scss';
import { userRoles } from '../../helpers/constants';
import feedbackIcon from '../../assets/images/3PLogo.svg';
import InfoIcon from '../../components/icon-info/info-icon';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import defaultVoteItemActivation from './info-feedback.constants';

const handleClick = (initialShowTip, initialActive, withinFeedback, name) => {
  let showTip;
  let active;
  if (name === 'cancel') {
    showTip = true;
    active = true;
  } else if (withinFeedback) {
    showTip = false;
    active = initialActive;
  } else if (name && !withinFeedback) {
    if (initialShowTip || initialActive) {
      showTip = false;
      active = false;
    } else if (!initialShowTip && !initialActive) {
      showTip = true;
      active = true;
    }
  } else if (!name && !withinFeedback) {
    showTip = false;
    active = false;
  }
  return { showTip, active };
};

const InfoFeedback = ({ className }) => {
  const { i18n } = useLocalisation();
  const feedbackRef = useRef(null);
  const [active, setActive] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [userFeedbackConfig, setUserFeedbackConfig] = useState({
    url: '',
    identifier: ''
  });
  const { queryStringReducer, userReducer } = useSelector(state => state);
  const isTeacher = userReducer.userRole === userRoles.TEACHER || queryStringReducer.isPreview;

  const tipText = isTeacher ?
    i18n._(t`3P Learning values teacher feedback on this activity. 
    Please let us know what you think, or if anything is broken.`) :
    i18n._(t`Click on any feedback icon to let Mathletics know how you liked this activity. 
    If something isn't working, click on the third icon.`);
  const getUserFeedbackConfig = async () => {
    setUserFeedbackConfig(JSON.parse(window._env_.UserFeedbackConfig));
  };

  const getVoteItems = () => {
    if (isTeacher) {
      return ['comment'];
    }
    return defaultVoteItemActivation.filter(item => item !== 'comment');
  };

  const handleAllClicks = (e, withinFeedback) => {
    /**
     * e                      | withinFeedback  | source of click
     * ---------------------------------------------------------------------
     * undefined              | false           | window
     * .target.name undefined | false           | InfoIcon btn
     * .target.name undefined | true            | UserFeedback non buttons
     * .target.name defined   | true            | UserFeedback buttons
     */
    const name = e && (e.target.name || true);
    const nextState = handleClick(showTip, active, withinFeedback, name);
    setActive(nextState.active);
    setShowTip(nextState.showTip);
  };

  useEffect(() => {
    getUserFeedbackConfig();
  }, []);

  return (
    <div className={classNames(css['info-feedback'], className)}>
      <div ref={feedbackRef}>
        <UserFeedback
          className={classNames(css['user-feedback'], { [css['user-feedback-active']]: active })}
          ratingIdentifier={userFeedbackConfig.identifier}
          localeCdnBaseUrl={userFeedbackConfig.localeCdnBaseUrl || ''}
          locale={queryStringReducer.locale || ''}
          itemId={queryStringReducer.girlId}
          authToken={Resources.env.authToken || ''}
          ratingEndPoint={userFeedbackConfig.url}
          expanded={active}
          voteItemsActivation={getVoteItems()}
          onSuccess={() => setActive(false)}
          onError={() => setActive(false)}
          onClick={e => handleAllClicks(e, true)}
        />
      </div>
      <InfoIcon
        className={classNames(css['btn-info'], { [css['btn-info-active']]: active })}
        infoIconData={{
          icon: feedbackIcon,
          paragraph: tipText,
          showTip,
          ignoredRef: feedbackRef,
          onClick: handleAllClicks
        }}
      />
    </div>
  );
};

InfoFeedback.propTypes = {
  className: PropTypes.string
};

export default InfoFeedback;
