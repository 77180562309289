exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".scan-paper-modal---3wRzl{width:400px;padding:20px 20px 0;text-align:left;font-size:14px}.scan-paper-modal---3wRzl h2{text-align:center;margin-bottom:10px;font-size:18px}.scan-paper-modal---3wRzl .modal-buttons---3_1hX{display:flex;justify-content:space-between;margin-top:30px}.scan-paper-modal---3wRzl .modal-buttons---3_1hX .button---2sXie{height:30px;padding:0 15px}.scan-paper-modal---3wRzl .modal-buttons---3_1hX .button---2sXie .icon---214Ce{margin-left:.5em}.scan-paper-modal---3wRzl .modal-buttons---3_1hX .button---2sXie .icon---214Ce svg{vertical-align:middle}", ""]);

// exports
exports.locals = {
	"scan-paper-modal": "scan-paper-modal---3wRzl",
	"modal-buttons": "modal-buttons---3_1hX",
	"button": "button---2sXie",
	"icon": "icon---214Ce"
};