import React from 'react';
import { t } from '@lingui/macro';
import { HStack, Img, Box } from '@chakra-ui/react';
import iconTick from '../../../assets/images/icon_tick_01.svg';
import iconCross from '../../../assets/images/icon_cross_01.svg';
import iconExclamation from '../../../assets/images/icon_exclamation_01.svg';
import { useLocalisation } from '../../../helpers/i18n/use-localisation';

export const useValidationCapsule = () => {
  const { i18n } = useLocalisation();
  const validationCapsule = (sectionResults, isCorrect) => {
    let numTotalParts = 0;
    let numPartsCorrect = 0;
    let btnIcon;
    let btnText;
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(sectionResults)) {
      numTotalParts += 1;
      if (value) {
        numPartsCorrect += 1;
      }
    }

    if (isCorrect) {
      btnIcon = iconTick;
      btnText = i18n._(t`All parts correct`);
    } else if (!isCorrect && numPartsCorrect > 0) {
      btnIcon = iconExclamation;
      btnText = `${numPartsCorrect}/${numTotalParts} ${i18n._(t`parts correct`)}`;
    } else {
      btnIcon = iconCross;
      btnText = i18n._(t`All parts incorrect`);
    }

    return (
      <HStack spacing="10px" bg="white" height="50px" px="40px" borderRadius="25px">
        <Img src={btnIcon} />
        <Box>{btnText}</Box>
      </HStack>
    );
  };

  return { validationCapsule };
};
