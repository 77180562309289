exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".error-handling---1c5wX{min-width:400px;padding:20px;font-weight:bold;font-size:23px;text-align:center;color:#00008b;align-items:center;position:relative;background-color:#e8e7e8;padding-bottom:20px;margin-bottom:-20px}.error-handling---1c5wX .icon---29ZEu{margin:20px 0}.error-handling---1c5wX .status-issue---SuF44{font-weight:bold;font-size:30px;color:#1eb4e6;margin-bottom:20px}.error-handling---1c5wX .action---1K3fV{margin-bottom:20px}", ""]);

// exports
exports.locals = {
	"error-handling": "error-handling---1c5wX",
	"icon": "icon---29ZEu",
	"status-issue": "status-issue---SuF44",
	"action": "action---1K3fV"
};