import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import { Trans } from '@lingui/macro';
import iconNewTab from '../../assets/images/ico_newtab.svg';
import { BtnText } from '../../components/buttons';
import css from './scan-paper-modal.scss';

const ScanPaperModal = ({ handleDownload, handleCancel }) => (
  <div className={css['scan-paper-modal']}>
    <h2><Trans>Printing Mathletics Paper?</Trans></h2>
    <Trans>
      <p>
        Mathletics Paper is a great way to record your work and thinking!
        You will need to copy your work onto this sheet of paper for your device to properly capture it.
      </p>
      <br />
      <p>Try to keep your work within the sections of the paper as you will need to scan them one at a time.</p>
      <br />
      <p>If you are having trouble printing, ask your teacher for help!</p>
    </Trans>
    <div className={css['modal-buttons']}>
      <BtnText
        className={css.button}
        callBack={handleCancel}
      >
        <Trans>Cancel</Trans>
      </BtnText>
      <BtnText
        className={css.button}
        callBack={handleDownload}
      >
        <Trans>Print</Trans>
        <InlineSVG className={css.icon} src={iconNewTab} />
      </BtnText>
    </div>
  </div>
);

ScanPaperModal.propTypes = {
  handleDownload: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default ScanPaperModal;
