export const ANSWER_UPDATED = 'ANSWER_UPDATED';
export const ANSWER_VALIDATED = 'ANSWER_VALIDATED';

export const updateAnswer = payload => ({
  type: ANSWER_UPDATED,
  payload
});

export const validateAnswer = (validation) => {
  return {
    type: ANSWER_VALIDATED,
    validation
  };
};
