exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".profile---3vXiG{display:flex;flex-direction:row;overflow:hidden}.profile---3vXiG .icon---zfXXm{pointer-events:none}.profile---3vXiG .icon---zfXXm svg{width:25px;height:25px}.profile---3vXiG .text---WKCH0{font-size:16px;padding-left:8px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}", ""]);

// exports
exports.locals = {
	"profile": "profile---3vXiG",
	"icon": "icon---zfXXm",
	"text": "text---WKCH0"
};