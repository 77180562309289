/* global URL document */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { forwardRef, useImperativeHandle, useState, Fragment, useEffect } from 'react';
import vmsg from 'vmsg/vmsg.es5';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { AudioPlayer, Slider } from '../../audio-controller';
import css from './record-audio-edit.scss';
import { BtnAudio, BtnText } from '../../../components/buttons';
import { activateError } from '../../error-handling/error-handling-actions';
import { disableWidgetEditorOk, disableWidgetEditorCancel } from '../../widget-editor/widget-editor-actions';

let vmsgRecorder;

document.addEventListener('DOMContentLoaded', () => {
  vmsgRecorder = new vmsg.Recorder({
    wasmURL: window._env_.VmsgWasm
  });
});

const RecordAudioEdit = forwardRef((props, ref) => {
  const [recording, setRecording] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [firstRecord, setFirstRecord] = useState(true);
  const [recordBtnDisabled, setRecordBtnDisabled] = useState(false);

  const { btnOkIsDisabled } = useSelector(state => state.widgetEditorReducer);

  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    getData: () => ({
      content: {
        audioUrl,
        audioBlob
      }
    })
  }));

  useEffect(() => {
    if (!audioBlob && !btnOkIsDisabled) {
      dispatch(disableWidgetEditorOk(true));
    }
  });

  const startRecording = async () => {
    dispatch(disableWidgetEditorOk(true));
    await window.navigator.mediaDevices.getUserMedia({
      audio: true
    }).then(async () => {
      dispatch(disableWidgetEditorCancel(true));
      try {
        await vmsgRecorder.initAudio();
        await vmsgRecorder.initWorker();
        vmsgRecorder.startRecording();
        setRecording(true);
      } catch (e) {
        setRecordBtnDisabled(true);
        dispatch(activateError(e.status));
      }
    }).catch((e) => {
      setRecordBtnDisabled(true);
      dispatch(activateError(e.status));
    });
  };

  const stopRecording = async () => {
    const blob = await vmsgRecorder.stopRecording();
    setRecording(false);
    setFirstRecord(false);
    setAudioBlob(blob);
    setAudioUrl(URL.createObjectURL(blob));
    dispatch(disableWidgetEditorOk(false));
    dispatch(disableWidgetEditorCancel(false));
  };

  const showRerecord = (!firstRecord && !recording);

  const renderPlayerControl = () => (
    <Fragment>
      {
        showRerecord && (
          <AudioPlayer
            src={audioUrl}
            isEdit
          />
        )
      }
    </Fragment>
  );

  const renderRecorderControl = () => (
    <div className={`${showRerecord ? `${css['re-record']} ` : ''}${css['recorder-control']}`}>
      {
        recording ? (
          <div className={css.container}>
            <BtnAudio
              callBack={stopRecording}
              disabled={recordBtnDisabled}
            />
            <Slider
              callBack={stopRecording}
              stop={!recording}
              className={css.slider}
              duration={30}
            />
          </div>
        ) : (
          <Fragment>
            {
              firstRecord ? (
                <div className={css['start-record']}>
                  <BtnAudio
                    callBack={startRecording}
                    disabled={recordBtnDisabled}
                    isRecord
                  />
                  <div className={css.txt}><Trans>Press to Record</Trans></div>
                </div>
              ) : (
                <BtnText className={css['btn-rerender']} callBack={startRecording}><Trans>Re-record</Trans></BtnText>
              )
            }
          </Fragment>
        )
      }
    </div>
  );

  return (
    <div className={css['record-audio-edit']}>
      {renderPlayerControl()}
      {renderRecorderControl()}
    </div>
  );
});

export default RecordAudioEdit;
