import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './btn-text.scss';

export const BtnText = ({
  isDisabled,
  children,
  callBack,
  className,
  isSmall,
  'data-testid': dataTestId
}) => (
  <button
    data-testid={dataTestId}
    className={classNames(css.button, className, { [css.small]: isSmall })}
    disabled={isDisabled}
    onClick={callBack}
  >
    {children}
  </button>
);

BtnText.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  callBack: PropTypes.func,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  'data-testid': PropTypes.string
};
