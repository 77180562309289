exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".slider---2q5Kp{width:100%;height:14px;background-color:#dbdbdb;position:relative;border-radius:14px}.slider---2q5Kp .audio-playhead---2Ni82{width:20px;height:20px;border-radius:22px;position:absolute;top:-4px;background-color:#fd7849;margin-right:10px}", ""]);

// exports
exports.locals = {
	"slider": "slider---2q5Kp",
	"audio-playhead": "audio-playhead---2Ni82"
};