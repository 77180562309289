import React, { useRef, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import css from './widget-editor.scss';
import { editors } from '../widgets';
import { BtnText } from '../../components/buttons';
import { widgetSave, widgetCancel } from '../provelet/provelet-actions';
import { resetMenu } from '../widget-menu/widget-menu-actions';
import { disableWidgetEditorOk } from '../widget-editor/widget-editor-actions';
import Loading from '../../components/loading/loading';
import WidgetHeader from './widget-header';

const WidgetEditor = ({ user }) => {
  const widgetRef = useRef(null);
  const widgetEditorRef = useRef(null);
  const [widgetEditorHeight, setWidgetEditorHeight] = useState(50);
  const [partNumber, setPartNumber] = useState(null);
  const dispatch = useDispatch();
  const { widgetEditorReducer, proveletReducer } = useSelector(state => state);

  const Widget = editors[proveletReducer.widgetEditor];

  const handleSave = () => {
    setWidgetEditorHeight(widgetEditorRef.current.getBoundingClientRect().height);
    const { content } = widgetRef.current.getData();
    dispatch(widgetSave({ ...content, partNumber }));
    dispatch(resetMenu());
  };

  const handleCancel = () => {
    dispatch(widgetCancel());
    dispatch(resetMenu());
    dispatch(disableWidgetEditorOk(false));
  };

  return (
    <div className={css['widget-editor']} ref={widgetEditorRef}>
      <div className={css.widget}>
        {
          widgetEditorReducer.loadingBlanket ? (
            <div
              className={css['loading-container']}
              style={{
                height: `${widgetEditorHeight}px`
              }}
            >
              <Loading />
            </div>
          ) : (
            <Fragment>
              <WidgetHeader
                onPartSelect={setPartNumber}
                selectedPart={partNumber}
                user={user}
              />
              <Widget ref={widgetRef} />
              <div className={css.footer}>
                <BtnText
                  callBack={handleCancel}
                  isSmall
                  isDisabled={widgetEditorReducer.btnCancelIsDisabled}
                >
                  <Trans>Cancel</Trans>
                </BtnText>
                <BtnText
                  callBack={handleSave}
                  isSmall
                  isDisabled={widgetEditorReducer.btnOkIsDisabled}
                >
                  <Trans>Save</Trans>
                </BtnText>
              </div>
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

WidgetEditor.propTypes = {
  user: PropTypes.string.isRequired
};

export default WidgetEditor;
