import _ from 'lodash';
import {
  DUCS_CONFIG_ACQUIRED,
  SCRIPT_LOAD_STATUS_SET,
  DUCS_PAGE_RENDERED,
  RESET_DUCS
} from './ducs-actions';

export const initialState = {
  ducsLibraryUrl: null,
  ducsConfiguration: null,
  isScriptLoaded: false,
  ducsPageRendered: false
};

export default (state = initialState, action) => {
  const cloned = _.clone(state);
  switch (action.type) {
    case DUCS_CONFIG_ACQUIRED: {
      cloned.ducsLibraryUrl = action.payload.ducsLibraryUrl;
      cloned.ducsConfiguration = action.payload.ducsConfiguration;
      return cloned;
    }

    case SCRIPT_LOAD_STATUS_SET: {
      cloned.isScriptLoaded = action.payload;
      return cloned;
    }

    case DUCS_PAGE_RENDERED:
      cloned.ducsPageRendered = action.status;
      return cloned;

    case RESET_DUCS: {
      cloned.ducsPageRendered = false;
      cloned.isScriptLoaded = false;
      return cloned;
    }

    default:
      return state;
  }
};
