/* global window */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Script from 'react-load-script';
import _ from 'lodash';
import { removeLearnosityKeypad } from '../../helpers/utils';
import {
  acquireDucsConfig,
  setScriptLoadStatus,
  mergeLayoutContent,
  setDucsPageRender
} from './ducs-actions';
import {
  ACTIVITY_MARKED,
  setActivityState,
  ACTIVITY_UNATTEMPTED,
  setActivityStatus
} from '../activity/activity-actions';

import {
  disableSpinner,
  ACTIVITY_RENDER_SPINNER
} from '../mle/mle-actions';

import css from './ducs.scss';
import { MODULE_NAME_ACTIVITY } from '../../helpers/constants';
import { activateError } from '../error-handling/error-handling-actions';
import totalLearnosityMarkingItems from './total-learnosity-marking-items';
import ducsStateParams from './ducs-state-params';

const Ducs = () => {
  const {
    initMleReducer,
    ducsReducer,
    catPageReducer,
    activityReducer
  } = useSelector(state => state);

  const dispatch = useDispatch();

  const ducsScriptOnload = () => {
    dispatch(setScriptLoadStatus(true));
  };

  const subscribeEvents = () => {
    const total = totalLearnosityMarkingItems(catPageReducer.rows);
    const ducsIdPrefix = 'ID_';
    window.DUCSApplication.subscribe(
      `${ducsIdPrefix}.js-ducs`,
      'ACTIVITY_QUESTION_CHANGED',
      ({ params }) => {
        params.total = total;
        dispatch(setActivityState(params));
      }
    );
    window.DUCSApplication.subscribe(
      `${ducsIdPrefix}.js-ducs`,
      'ERROR_EVENT_DISPATCHED',
      (params) => {
        dispatch(activateError(params.code));
      }
    );
    window.DUCSApplication.subscribe(
      `${ducsIdPrefix}.js-ducs`,
      'PAGE_LOADED',
      () => {
        dispatch(setDucsPageRender(true));
      }
    );
  };

  useEffect(() => {
    if (ducsReducer.ducsPageRendered) {
      dispatch(disableSpinner(ACTIVITY_RENDER_SPINNER));
    }
  }, [ducsReducer.ducsPageRendered]);

  useEffect(() => {
    // When component did mount
    removeLearnosityKeypad();
    dispatch(acquireDucsConfig());
  }, []);

  useEffect(() => {
    // Merge layout, content and current state
    if (initMleReducer.length > 0 && _.has(ducsReducer.ducsConfiguration, 'CdnBasePath')) {
      const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
      const { CdnBasePath } = ducsReducer.ducsConfiguration;
      const { layoutPath, contentPath } = activityData.meta;
      dispatch(mergeLayoutContent({
        layoutPath: `${CdnBasePath}${layoutPath}`,
        contentPath: `${CdnBasePath}${contentPath}`,
        data: activityData.data
      }));
    }
  }, [ducsReducer.ducsLibraryUrl, initMleReducer]);

  const loadActivity = () => {
    // Instantiate DUCS and renders activity
    if (ducsReducer.isScriptLoaded && !_.isEmpty(catPageReducer)) {
      const total = totalLearnosityMarkingItems(catPageReducer.rows);
      dispatch(setActivityState({
        total
      }));
      const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
      const activityState = _.get(activityData, 'data.activityState') || {};
      let params = {
        json: catPageReducer
      };
      const hasChecked = _.get(activityData, 'data.activityStatus') === ACTIVITY_MARKED;
      if (hasChecked) {
        dispatch(setActivityStatus(ACTIVITY_MARKED, activityData));

        params = ducsStateParams({
          activityData,
          activityState
        });
      } else {
        const hasNoResponse = _.isEmpty(activityReducer.activityState);
        if (hasNoResponse) {
          params.learnosityInitialState = {
            state: 'initial',
            useDefaultFeedback: true,
            responses: {}
          };
        } else {
          params = ducsStateParams({
            activityData,
            activityState: activityReducer.activityState,
            state: activityReducer.activityStatus === ACTIVITY_MARKED ? 'review' : 'resume'
          });
        }
        dispatch(setActivityStatus(ACTIVITY_UNATTEMPTED));
      }
      window.DUCSApplication.initialise(
        '.js-ducs',
        {
          configuration: ducsReducer.ducsConfiguration,
          activateAutoFocus: true,
          ...params
        }
      );
      subscribeEvents();
    }
  };

  useEffect(() => {
    loadActivity();
  }, [ducsReducer.isScriptLoaded, catPageReducer]);

  useEffect(() => {
    if (activityReducer.activityStatus === ACTIVITY_MARKED) {
      try {
        window.DUCSApplication.publish(
          '.js-ducs',
          'ACTIVITY_QUESTION_MARKED',
          {}
        );
      } catch (e) {
        loadActivity();
      }
    }
  }, [activityReducer.activityStatus]);

  return (
    <div className={css.ducs}>
      {
        ducsReducer.ducsLibraryUrl &&
        catPageReducer &&
        !ducsReducer.isScriptLoaded &&
        (
          <Script
            url={ducsReducer.ducsLibraryUrl}
            onLoad={ducsScriptOnload}
          />
        )
      }
      <div className={'js-ducs'} />
    </div>
  );
};

export default Ducs;
