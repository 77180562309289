exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".text-view---2O0sZ{color:#545454;white-space:pre-wrap;word-break:break-all;overflow:hidden}", ""]);

// exports
exports.locals = {
	"text-view": "text-view---2O0sZ"
};