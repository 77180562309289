import { START_TIME_RECORDED } from './mle-actions';

export const initialState = {
  startTime: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_TIME_RECORDED: {
      return {
        ...state,
        startTime: action.startTime
      };
    }

    default:
      return state;
  }
};
