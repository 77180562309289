import Resources from './resources';
import { activateError } from '../containers/error-handling/error-handling-actions';

class Intercept3pResourcesError {
  static addResourceResponseErrorInterceptor(dispatch) {
    const interceptor = Intercept3pResourcesError.handleErrors.bind(this, dispatch);
    Resources.env.addResponseInterceptor(interceptor);
  }

  static handleErrors(dispatch, result) {
    let valid = true;
    try {
      if (result.status > 399 && result.status < 600) {
        valid = false;
      }
    } catch (e) {
      valid = false;
    }

    if (!valid) {
      dispatch(activateError(result.status));
    }

    result.valid = valid;

    return result;
  }
}

export default Intercept3pResourcesError;
