import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Script from 'react-load-script';
import Measure from 'react-measure';
import _ from 'lodash';
import {
  acquireGeogebraUrl,
  loadGeogebraScript
} from './scratchpad-edit-actions';
import { disableWidgetEditorOk } from '../../widget-editor/widget-editor-actions';
import css from './scratchpad-edit.scss';
import { useLocalisation } from '../../../helpers/i18n/use-localisation';

const ScratchpadEdit = forwardRef((props, externalRef) => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const GGB_EDITOR_MIN_HEIGHT = 500;
  const GGB_EDITOR_MIN_WIDTH = 50;
  const [ggbApplet, setGgbApplet] = useState(null);
  const [hasDrawing, setHasDrawing] = useState(false);

  const { geogebraReducer, widgetEditorReducer: { btnOkIsDisabled } } = useSelector(state => state);
  const containerRef = useRef(null);

  useImperativeHandle(externalRef, () => ({
    getData() {
      return {
        content: {
          canvas: ggbApplet.getPNGBase64(1, true),
          isGeoGebra: true
        }
      };
    }
  }));

  const onGgbLoad = (ggbApi) => {
    setGgbApplet(ggbApi);
    window.onDrawingAdded = () => {
      setHasDrawing(true);
    };
    window.onDrawingUpdated = () => {
      const objectCount = ggbApi.getObjectNumber();
      setHasDrawing(objectCount !== 0);
    };
    ggbApi.registerAddListener('onDrawingAdded');
    ggbApi.registerRemoveListener('onDrawingUpdated');

    // on Edge, when trying to draw, the entire page is being highlighted with text selection.
    // Below code is to prvent that behavior
    const stopSelection = (event) => {
      event.preventDefault();
      event.stopPropagation();
      return false;
    };
    containerRef.current.addEventListener('selectstart', stopSelection);
  };

  useEffect(() => {
    if (!geogebraReducer.isScriptLoaded && !geogebraReducer.libraryUrl) {
      dispatch(acquireGeogebraUrl());
    }
  }, []);

  useEffect(() => {
    if (btnOkIsDisabled && hasDrawing) {
      dispatch(disableWidgetEditorOk(false));
    } else if (!btnOkIsDisabled && !hasDrawing) {
      dispatch(disableWidgetEditorOk(true));
    }
  }, [hasDrawing]);

  useEffect(() => {
    if (geogebraReducer.isScriptLoaded) {
      const ggbApp = new window.GGBApplet({
        appName: 'notes',
        width: containerRef.current.offsetWidth,
        height: Math.max(containerRef.current.offsetWidth / 3, GGB_EDITOR_MIN_HEIGHT),
        showToolBar: true,
        showAlgebraInput: true,
        showMenuBar: true,
        appletOnLoad: onGgbLoad,
        language: i18n.language
      }, true);
      ggbApp.inject('ggb-element');
    }
  }, [geogebraReducer.isScriptLoaded]);

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        if (ggbApplet) {
          ggbApplet.setWidth(GGB_EDITOR_MIN_WIDTH); // this scales down the container when provelet is collapsed
        }
        const updatedCanvasWidth = _.get(contentRect, 'bounds.width', 0);
        if (updatedCanvasWidth && ggbApplet) {
          ggbApplet.setWidth(updatedCanvasWidth);
        }
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef}>
          <div className={css['scratchpad-edit']} ref={containerRef}>
            {
              geogebraReducer.libraryUrl &&
              !geogebraReducer.isScriptLoaded &&
              (
                <Script
                  url={geogebraReducer.libraryUrl}
                  onLoad={() => dispatch(loadGeogebraScript(true))}
                />
              )
            }
            <div id="ggb-element" />
          </div>
        </div>
      )}
    </Measure>
  );
});

export default ScratchpadEdit;

