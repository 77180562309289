import { useDispatch, useSelector } from 'react-redux';
import { userRoles } from '../../../helpers/constants';
import { completeActivity, disableSpinner, enableSpinner, recordTime, TIMESTAMP_COMPLETE } from '../../mle/mle-actions';
import { FINISH_SPINNER } from '../footer-qtc.actions';

export const useFinish = () => {
  const dispatch = useDispatch();
  const { userReducer, queryStringReducer } = useSelector(state => state);
  const isTeacher = userReducer.userRole === userRoles.TEACHER || queryStringReducer.isPreview;

  const completeCallback = () => {
    window.history.back();
  };

  const handleFinish = async () => {
    dispatch(enableSpinner(FINISH_SPINNER));
    if (isTeacher) {
      completeCallback();
      return;
    }
    await dispatch(recordTime(TIMESTAMP_COMPLETE));
    dispatch(completeActivity({
      completeCallback
    }));
    dispatch(disableSpinner(FINISH_SPINNER));
  };

  return { handleFinish };
};
