import { INIT_MLE_DATA_ACQUIRED } from './mle-actions';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case INIT_MLE_DATA_ACQUIRED: {
      return action.payload;
    }

    default:
      return state;
  }
};
