import React, { useState } from 'react';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import iconQuestion from '../../assets/images/icon_questionmark.svg';
import css from './info.scss';
import InfoIcon from '../../components/icon-info/info-icon';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const Info = () => {
  const { i18n } = useLocalisation();
  const [showTip, setShowTip] = useState(false);

  const infoIconData = {
    icon: iconQuestion,
    paragraph: `${i18n._(t`The left side of the page shows the problem solving questions.
    You can use the right side to write, record or scribble your work, or send your teacher a message.
    You may also be able to enter your answers on the left side.`)}\n\n${i18n._(t`Your teacher can
    also send you a message, which will be flagged in your student console.`)}`,
    showTip,
    onClick: e => setShowTip(e ? !showTip : false)
  };

  return (
    <InfoIcon
      className={classNames(css['info-icon'], { [css['info-icon-active']]: showTip })}
      infoIconData={infoIconData}
    />
  );
};


export default Info;
