export const GEOGEBRA_URL_ACQUIRED = 'GEOGEBRA_URL_ACQUIRED';
export const GEOGEBRA_SCRIPT_LOADED = 'GEOGEBRA_SCRIPT_LOADED';

export const acquireGeogebraUrl = () => (dispatch) => {
  dispatch({
    type: GEOGEBRA_URL_ACQUIRED,
    geogebraLibraryUrl: window._env_.GeogebraLibraryUrl
  });
};

export const loadGeogebraScript = isLoaded => ({
  type: GEOGEBRA_SCRIPT_LOADED,
  isLoaded
});
