import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useLocalisation } from '../../../helpers/i18n/use-localisation';
import { MODULE_NAME_ACTIVITY, userRoles } from '../../../helpers/constants';
import Resources from '../../../helpers/resources';

export const useTeacherNotes = () => {
  const { i18n } = useLocalisation();
  const { initMleReducer, userReducer, queryStringReducer } = useSelector(state => state);
  const isTeacher = userReducer.userRole === userRoles.TEACHER || queryStringReducer.isPreview;

  const getTeacherNotesResourceId = () => {
    const activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
    return _.get(activityData, 'meta.teacherNotes');
  };

  const checkIsTeacher = isTeacher && getTeacherNotesResourceId();

  const handleTeacherNotes = () => {
    window.open(
      // eslint-disable-next-line max-len
      `${window._env_.TeacherNotesUrl}/${queryStringReducer.girlId}?authToken=${Resources.env.authToken}&locale=${i18n.language}`
    );
  };

  return { checkIsTeacher, handleTeacherNotes };
};
