import _ from 'lodash';
import {
  WIDGET_EDITOR_RESET,
  WIDGET_EDITOR_OK_DISABLED,
  WIDGET_EDITOR_CANCEL_DISABLED,
  WIDGET_LOADING_ENABLED
} from './widget-editor-actions';

export const initialState = {
  btnOkIsDisabled: true,
  btnCancelIsDisabled: false,
  loadingBlanket: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case WIDGET_EDITOR_RESET: {
      return initialState;
    }

    case WIDGET_EDITOR_OK_DISABLED: {
      const cloned = _.clone(state);
      cloned.btnOkIsDisabled = action.isDisabled;
      return cloned;
    }

    case WIDGET_EDITOR_CANCEL_DISABLED: {
      const cloned = _.clone(state);
      cloned.btnCancelIsDisabled = action.isDisabled;
      return cloned;
    }

    case WIDGET_LOADING_ENABLED: {
      const cloned = _.clone(state);
      cloned.loadingBlanket = action.isEnabled;
      return cloned;
    }

    default:
      return state;
  }
};
