import TextEdit from './text/text-edit';
import RecordAudioEdit from './record-audio/record-audio-edit';
import ScratchpadEdit from './scratchpad/scratchpad-edit';
import ScanEdit from './scan/scan-edit';

import TextView from './text/text-view';
import RecordAudioView from './record-audio/record-audio-view';
import ScratchpadView from './scratchpad/scratchpad-view';
import ScanView from './scan/scan-view';

export const editors = {
  Text: TextEdit,
  RecordAudio: RecordAudioEdit,
  Scratchpad: ScratchpadEdit,
  Scan: ScanEdit
};

export const viewers = {
  Text: TextView,
  RecordAudio: RecordAudioView,
  Scratchpad: ScratchpadView,
  Scan: ScanView
};
