import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InlineSVG from 'react-inlinesvg';
import { t } from '@lingui/macro';
import iconEnvelope from '../../assets/images/icon_envelope.svg';
import css from './widgets-view.scss';
import { viewers } from '../widgets';
import DateHeader from '../date-header/date-header';
import Triangle from '../../components/triangle/triangle';
import { userRoles } from '../../helpers/constants';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const WidgetsView = ({ user, data }) => {
  const { i18n } = useLocalisation();
  let prevDate = null;
  return (
    <div className={css['widgets-view']}>
      {
        data.map(({ widgetName, widgetData: { renderAs, ...content }, createdUTC }) => {
          const Widget = viewers[widgetName];
          const date = new Date(createdUTC);
          const align = renderAs.user === user ? 'right' : 'left';
          const icon = renderAs.isQuestion || renderAs.isReply;

          const view = (
            <React.Fragment key={date}>
              <DateHeader date={date} prevDate={prevDate} />
              {content.partNumber && (
                <div className={classNames(css['widget-reference-text'], { [css.padded]: align === 'right' })}>
                  {
                    renderAs.user === userRoles.TEACHER ?
                      i18n._(t`Re:`) :
                      i18n._(t`I'm working on`)} {i18n._(t`Part ${content.partNumber}`)
                  }
                </div>
              )}
              <div className={classNames(css['widget-wrapper'], { [css.question]: icon }, css[align])}>
                {
                  icon &&
                  <div className={css.icon}>
                    <InlineSVG
                      src={iconEnvelope}
                      className={classNames(
                        css['envelope-icon'],
                        { [css['envelope-icon-grey']]: align === 'right' }
                      )} />
                    <Triangle
                      className={css.triangle}
                      leftPx={'8'}
                      rightPx={'0'}
                      topPx={'10'}
                      color={'#5f5f5f'} />
                  </div>
                }
                <div className={css.widget}>
                  <Widget data={content} />
                </div>
              </div>
            </React.Fragment>
          );

          prevDate = date;

          return view;
        })
      }
    </div>
  );
};


WidgetsView.propTypes = {
  user: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.exact({
      widgetName: PropTypes.string.isRequired,
      widgetData: PropTypes.shape({
        renderAs: PropTypes.exact({
          user: PropTypes.string.isRequired,
          isQuestion: PropTypes.bool.isRequired,
          isReply: PropTypes.bool.isRequired
        }).isRequired
      }).isRequired,
      createdUTC: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
};

export default WidgetsView;
