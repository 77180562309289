export const MODULE_NAME_ACTIVITY = 'activity';
export const MODULE_NAME_PROVELET = 'provelet';
export const MODULE_TYPE_CAT = 'cat';
export const MODULE_TYPE_DEFAULT = 'default';

export const proveletStates = {
  DEFAULT: 0,
  PROVELET_CHANGED: 1,
  QUESTION_ASKED: 2,
  TEACHER_REPLIED: 4,
  TEACHER_REVIEWED: 8
};

export const userRoles = {
  STUDENT: 'student',
  TEACHER: 'teacher',
  DEFAULT: 'student'
};

export const userTypes = {
  HOMESTUDENT: 'HomeStudent',
  SCHOOLSTUDENT: 'SchoolStudent',
  DEFAULT: 'HomeStudent'
};

export const timestamps = {
  STUDENT_ENTERED: 'student-entered',
  STUDENT_CHECKED_ACTIVITY: 'student-checked-activity',
  STUDENT_COMPLETED_ACTIVITY: 'student-completed-activity',
  STUDENT_LEFT: 'student-left',
  TEACHER_ENTERED: 'teacher-entered',
  TEACHER_LEFT: 'teacher-left',
  TEACHER_ENTERED_PREVIEW: 'teacher-entered-preview',
  TEACHER_LEFT_PREVIEW: 'teacher-left-preview'
};

export const LOCALE_DEFAULT = 'en-AU';

export const TAG_DELIMITER = '&';

export const RESULT_TYPES = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  PARTIAL: 'partial'
};

export const pdfHtml = 'pdf-loader.html';
