import { extendTheme } from '@chakra-ui/react';

export const mleTheme = extendTheme({
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        color: 'white'
      },
      variants: {
        mle: {
          h: '50px',
          fontSize: '16px',
          px: '40px',
          bg: '#3477c7',
          _hover: {
            bg: '#fd7849',
            _disabled: {
              bg: '#3477c7'
            }
          }
        }
      }
    }
  }
});
