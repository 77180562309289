import BasicResources from './basic-resources';

class Resources {
  static createResources(parentEnv) {
    if (parentEnv) {
      Resources.env = parentEnv;
    }

    const env = Resources.env;

    const BASE_URL = window._env_.MLEGateway;

    Resources.getTeacherModules = Resources.env
      .resource('getTeacherModules')
      .baseUrl(BASE_URL)
      .service('/modules/users/{userId}/resources/{girlId}');

    Resources.getStudentModules = Resources.env
      .resource('getStudentModules')
      .baseUrl(BASE_URL)
      .service('/modules/resources/{girlId}');

    Resources.saveStudentModule = Resources.env
      .resource('saveStudentModule')
      .baseUrl(BASE_URL)
      .service('/save-module-content');

    Resources.saveTeacherModule = Resources.env
      .resource('saveTeacherModule')
      .baseUrl(BASE_URL)
      .service('/save-module-content/users/{userId}');

    Resources.saveStudentResource = Resources.env
      .resource('saveStudentResource')
      .baseUrl(BASE_URL)
      .service('/resource-files');

    Resources.saveTeacherResource = Resources.env
      .resource('saveTeacherResource')
      .baseUrl(BASE_URL)
      .service('/users/{userId}/resource-files');

    Resources.completeActivity = Resources.env
      .resource('completeActivity')
      .baseUrl(BASE_URL)
      .service('/complete-activity');

    Resources.events = Resources.env
      .resource('events')
      .baseUrl(BASE_URL)
      .service('/events');

    Resources.getActivityStatus = Resources.env
      .resource('getActivityStatus')
      .baseUrl(BASE_URL)
      .service('/products/{productId}/resources/{resourceId}/status');

    Resources.assignDefault = Resources.env
      .resource('assignDefault')
      .baseUrl(BASE_URL)
      .service('/assignDefault');

    Resources.saveActivityModules = Resources.env
      .resource('saveActivityModules')
      .baseUrl(BASE_URL)
      .service('/save-activity-modules');

    Resources.getConfigurationItem = async key => env.getConfigurationItem(key);
  }

  static initialise(envName, credentials) {
    Resources.env = BasicResources.initialise(envName, credentials);

    Resources.createResources();

    return Resources.env;
  }
}

export default Resources;

