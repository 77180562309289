import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import Bowser from 'bowser';
import { t, Trans } from '@lingui/macro';
import iconAdd from '../../assets/images/icon-add.svg';
import iconWrite from '../../assets/images/icon_write.svg';
import iconRecord from '../../assets/images/ico_audio.svg';
import iconScribble from '../../assets/images/ico_widget_scribble.svg';
import iconCamera from '../../assets/images/ico_camera.svg';
import css from './add-widget.scss';
import { activateBtn, WIDGET_BTN } from '../widget-menu/widget-menu-actions';
import { widgetEdit } from '../provelet/provelet-actions';
import { constructModal, activateModal } from '../modal/modal-actions';
import BrowserSupportModal from '../browser-support-modal/browser-support-modal';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const AddWidget = ({
  className
}) => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const widgetMenuReducer = useSelector(state => state.widgetMenuReducer);
  const widgets = [
    {
      id: 'Text',
      label: i18n._(t`Write`),
      icon: iconWrite
    },
    {
      id: 'RecordAudio',
      label: i18n._(t`Record`),
      icon: iconRecord
    },
    {
      id: 'Scratchpad',
      label: i18n._(t`Scribble`),
      icon: iconScribble
    },
    {
      id: 'Scan',
      label: i18n._(t`Scan`),
      icon: iconCamera
    }
  ];

  const handleWidget = (id) => {
    if (id === 'RecordAudio') {
      let action;
      let issue;
      let showModal = false;
      const browser = Bowser.getParser(window.navigator.userAgent);
      const platformType = browser.getPlatformType();
      if ((platformType === 'mobile' || platformType === 'tablet') && !browser.getBrowserVersion()) {
        issue = i18n._(t`Recording is not available on this App`);
        showModal = true;
      } else {
        issue = i18n._(t`Recording is not working`);
        if (browser.satisfies({ ie: '<=11' })) {
          action = i18n._(t`Use a different browser to Internet Explorer`);
          showModal = true;
        } else if (browser.satisfies({ safari: '<=10' })) {
          action = i18n._(t`Update your Safari browser`);
          showModal = true;
        }
      }
      if (showModal) {
        dispatch(constructModal({
          activate: true,
          content: (
            <BrowserSupportModal
              issue={issue}
              action={action}
              handleOk={() => dispatch(activateModal(false))}
            />
          ),
          canvasOnClick: false
        }));
        return;
      }
    }
    dispatch(widgetEdit(id));
  };

  return (
    <div
      className={`${css['add-widget']} ${css[widgetMenuReducer[WIDGET_BTN]]} ${className || ''}`}
      onClick={() => dispatch(activateBtn(WIDGET_BTN))}
      role="presentation"
    >
      {
        widgetMenuReducer[WIDGET_BTN] === '' ||
          widgetMenuReducer[WIDGET_BTN] === 'contract' ?
          (
            <div className={css['btn-elm']} title={i18n._(t`Show my work`)}>
              <InlineSVG className={css.icon} src={iconAdd} />
              <p className={css.text}><Trans>Show my work</Trans></p>
            </div>
          ) : (
            <ul className={css.btns}>
              {
                widgets.map(widget => (
                  <li
                    key={widget.id}
                    className={css.btn}
                    role="presentation"
                    onClick={() => handleWidget(widget.id)}
                  >
                    <InlineSVG className={css['btn-icon']} src={widget.icon} />
                    <p className={css['btn-text']}>{widget.label}</p>
                  </li>
                ))
              }
              <li key="blank" className={css.blank} />
            </ul>
          )
      }

    </div>
  );
};

AddWidget.propTypes = {
  className: PropTypes.string
};

export default AddWidget;
