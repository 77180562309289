exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".widget-menu---3g4M7{display:flex}", ""]);

// exports
exports.locals = {
	"widget-menu": "widget-menu---3g4M7"
};