import React, { useEffect, useState, useContext } from 'react';
import { QtcRenderer, ReadAloud, KeypadContext } from '@3plearning/question-components-library';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Img, Text } from '@chakra-ui/react';
import { t } from '@lingui/macro';
import { MODULE_NAME_ACTIVITY } from '../../helpers/constants';
import {
  disableSpinner,
  ACTIVITY_RENDER_SPINNER
} from '../mle/mle-actions';
import { updateAnswer } from './conquest.actions';
import css from './conquest.scss';
import { useLocalisation } from '../../helpers/i18n/use-localisation';
import { useReadAloud } from './hooks/use-read-aloud';
import keypadIcon from '../../assets/images/keypad.svg';

const Conquest = () => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const [question, setQuestion] = useState([]);
  const { initMleReducer, conquestReducer, queryStringReducer } = useSelector(state => state);
  const { readAloudConfig, onLocaleChange, onToggleEnableState } = useReadAloud();
  const {
    keypadRef, showKeypad, setShowKeypad, showKeypadBtn, isKeypadBtnDisabled
  } = useContext(KeypadContext);
  const keypadPresetFromQueryParams = queryStringReducer.keypadPreset;
  let activityData;

  const feedback = ({ answer, isReady }) => {
    if (isReady) {
      dispatch(updateAnswer({ answer, isReady }));
    }
  };

  const toggleKeypad = (event) => {
    event.preventDefault();
    setShowKeypad(!showKeypad);
  };

  useEffect(() => {
    onLocaleChange(queryStringReducer.locale);
    dispatch(disableSpinner(ACTIVITY_RENDER_SPINNER));

    if (initMleReducer.length > 0) {
      activityData = initMleReducer.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
      setQuestion(activityData.questionDetails.questionVariant.questionItem.questionContent.content);
    }

    if (activityData.data) {
      dispatch(updateAnswer({
        answer: activityData.data.studentAnswer,
        isReady: false,
        validation: activityData.data.validationResult
      }));
    }
  }, []);

  return (
    <React.Fragment>
      <Box className={css['read-aloud-container']}>
        <ReadAloud
          onLocaleChange={locale => onLocaleChange(locale)}
          onToggleEnableState={enabled => onToggleEnableState(enabled)}
          popoverLabel={i18n._(t`Read aloud`)}
          selectedLocale={/es-/ig.test(queryStringReducer.locale) ? 'es-ES' : queryStringReducer.locale}
          disabled
        />
      </Box>
      {showKeypadBtn && <Button
        className={`${css['keypad-btn']} ${showKeypad && !isKeypadBtnDisabled && css['keypad-activated']}`}
        onMouseDown={toggleKeypad}
        isDisabled={isKeypadBtnDisabled}
      >
        <Img src={keypadIcon} />
      </Button>}
      {keypadRef.current && <QtcRenderer
        question={question}
        callback={feedback}
        validation={conquestReducer.validation}
        keypadPreset={
          keypadPresetFromQueryParams ? Number(keypadPresetFromQueryParams) : 3
        }
        keypadContainer={keypadRef.current}
        readAloudConfig={readAloudConfig}
        userResponse={conquestReducer.answer}
      />}
      {
        conquestReducer.validation && !conquestReducer.validation.isCorrect && (
          <Box className={css['correct-answer']} >
            <Text className={css['correct-answer-text']} >
              {i18n._(t`Here's the correct answer`)}
            </Text>
            <Box className={css['correct-answer-container']}>
              <QtcRenderer
                callback={() => undefined}
                question={question}
                showCorrectAnswer
                validation={conquestReducer.validation}
              />
            </Box>
          </Box>
        )
      }
    </React.Fragment>
  );
};

export default Conquest;
