exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".activity---1THe9{width:calc(50% - 25px);margin:0 5px 0 20px;background-color:#fff;border-radius:16px 0 0 16px;position:relative}.activity---1THe9 .audio-container---2JG5s{top:65px;z-index:1000}.activity---1THe9 .audio-container---2JG5s .react-draggable{position:absolute;top:380px;width:600px}.activity---1THe9 .audio-container---2JG5s .react-draggable .translated-text-modal-content{height:120px}.activity---1THe9 .audio-container---2JG5s button{display:flex;justify-content:center;align-items:center}.activity---1THe9 .audio-container---2JG5s button svg{display:unset}.activity---1THe9 .inner-scroll---J442S{overflow-y:auto;padding:20px 20px 20px 45px;padding-right:30px;width:100%;height:100%;position:absolute}.activity---1THe9 .inner-scroll---J442S .qtc-wrapper{font-size:unset}.activity---1THe9 .inner-scroll---J442S .qtc-wrapper *[data-type=\"default-content\"]{line-height:normal}.activity---1THe9 .tri---2dQKL{position:absolute;right:0;bottom:0;transform:translate(0, 100%)}@media only screen and (max-width: 1200px){.activity---1THe9{width:calc(100vw - 50px);margin:0;border-radius:0}}.activity---1THe9 .keyboard-btn---wPg7D{position:absolute;left:20px;bottom:25px;border-radius:20px;padding:0;background:#fd7849;transform:translateX(-50%);height:40px;width:40px;z-index:1;display:flex;justify-content:center;align-items:center}.activity---1THe9 .keyboard-btn---wPg7D.active---1Lnfc{background:#3477c7}.activity---1THe9 .keyboard-btn---wPg7D svg{width:22px;height:22px;vertical-align:middle}", ""]);

// exports
exports.locals = {
	"activity": "activity---1THe9",
	"audio-container": "audio-container---2JG5s",
	"inner-scroll": "inner-scroll---J442S",
	"tri": "tri---2dQKL",
	"keyboard-btn": "keyboard-btn---wPg7D",
	"active": "active---1Lnfc"
};