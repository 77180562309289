import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import Selectbox from '../../components/selectbox/selectbox';
import css from './widget-header.scss';
import { userRoles } from '../../helpers/constants';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const getPartReferenceOptions = i18n => [
  { value: '', label: i18n._(t`No specific part`) },
  { value: 'A', label: i18n._(t`Part ${'A'}`) },
  { value: 'B', label: i18n._(t`Part ${'B'}`) },
  { value: 'C', label: i18n._(t`Part ${'C'}`) },
  { value: 'D', label: i18n._(t`Part ${'D'}`) },
  { value: 'E', label: i18n._(t`Part ${'E'}`) },
  { value: 'F', label: i18n._(t`Part ${'F'}`) }
];

const WidgetHeader = ({ onPartSelect, selectedPart, user }) => {
  const { i18n } = useLocalisation();
  return (
    <div className={css['widget-header']}>
      <span className={css['widget-header-text']}>
        {user === userRoles.TEACHER ? i18n._(t`Re: `) : i18n._(t`I'm working on:`)}
      </span>
      <Selectbox
        className={css['widget-select']}
        onSelect={onPartSelect}
        options={getPartReferenceOptions(i18n)}
        value={selectedPart}
        placeholder={i18n._(t`Select one...`)}
      />
    </div>
  );
};

WidgetHeader.propTypes = {
  onPartSelect: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  selectedPart: PropTypes.string
};

export default WidgetHeader;
