import React from 'react';
import styleable from 'react-styleable/lib/styleable';
import { Trans } from '@lingui/macro';
import { BtnText } from '../../components/buttons';
import css from './footer-modals.scss';

const handleOk = () => {
  if (window.history.length === 1) {
    window.close();
  } else {
    window.history.back();
  }
};

const UnassignedModal = () => (
  <div className={css['check-confirm-modal']}>
    <h2><Trans>Attention!</Trans></h2>
    <p>
      <Trans>
        Your teacher has unassigned this activity. It will no longer be visible in your activity list.
      </Trans>
    </p>
    <div className={css['modal-button']}>
      <BtnText callBack={handleOk}>Ok</BtnText>
    </div>
  </div>
);

export default styleable(css)(UnassignedModal);
