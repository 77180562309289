import {
  LAYOUT_CONTENT_MERGED, RESET_DUCS
} from './ducs-actions';

export const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_CONTENT_MERGED:
      return action.payload;

    case RESET_DUCS:
      return null;

    default:
      return state;
  }
};
