export const WIDGET_EDITOR_RESET = 'WIDGET_EDITOR_RESET';
export const WIDGET_EDITOR_OK_DISABLED = 'WIDGET_EDITOR_OK_DISABLED';
export const WIDGET_EDITOR_CANCEL_DISABLED = 'WIDGET_EDITOR_CANCEL_DISABLED';
export const WIDGET_LOADING_ENABLED = 'WIDGET_LOADING_ENABLED';

export const resetWidgetEditor = () => ({
  type: WIDGET_EDITOR_RESET
});

export const disableWidgetEditorOk = isDisabled => ({
  type: WIDGET_EDITOR_OK_DISABLED,
  isDisabled
});

export const disableWidgetEditorCancel = isDisabled => ({
  type: WIDGET_EDITOR_CANCEL_DISABLED,
  isDisabled
});

export const enableLoadingBlanket = isEnabled => ({
  type: WIDGET_LOADING_ENABLED,
  isEnabled
});
