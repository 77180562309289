import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import css from './text-edit.scss';
import { disableWidgetEditorOk } from '../../widget-editor/widget-editor-actions';


const TextEdit = forwardRef((props, ref) => {
  const textareaRef = useRef(null);
  const dispatch = useDispatch();

  const [textValue, setTextValue] = useState('');

  const { btnOkIsDisabled } = useSelector(state => state.widgetEditorReducer);

  useImperativeHandle(ref, () => ({
    getData: () => ({
      content: { text: textareaRef.current.value.trim() }
    })
  }));

  const scrollIntoView = () => {
    window.requestAnimationFrame(() => {
      textareaRef.current.scrollIntoView(true);
    });
  };

  useEffect(() => {
    // to avoid textarea overlapping by virtual keyboard on android devices
    window.addEventListener('resize', scrollIntoView);
    textareaRef.current.focus();
    scrollIntoView();
    return () => window.removeEventListener('resize', scrollIntoView);
  }, []);

  useEffect(() => {
    if (!textareaRef.current.value || textareaRef.current.value.trim() === '') {
      if (!btnOkIsDisabled) dispatch(disableWidgetEditorOk(true));
    } else if (btnOkIsDisabled) {
      dispatch(disableWidgetEditorOk(false));
    }
  }, [textValue]);

  return (
    <div className={css.text}>
      <textarea
        data-testid="text-input"
        className={css['text-widget']}
        rows="4"
        ref={textareaRef}
        onChange={e => setTextValue(e.target.value)}
      />
    </div>
  );
});

TextEdit.propTypes = {
};

export default TextEdit;
