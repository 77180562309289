import _ from 'lodash';
import {
  ACTIVITY_STATUS_SET,
  ACTIVITY_UNATTEMPTED,
  ACTIVITY_STATE_SET,
  SAVE_SEGMENT_REQUEST_INITIATED,
  SAVE_SEGMENT_REQUEST_COMPLETED,
  ACTIVITY_MARKED
} from './activity-actions';
import {
  INIT_MLE_DATA_REQUESTED,
  INIT_MLE_DATA_FAILED,
  INIT_MLE_DATA_ACQUIRED,
  ASSIGNMENT_STATUS_ACQUIRED,
  ASSIGNMENT_STATUS_REQUESTED,
  ASSIGNMENT_STATUS_FAILED
} from '../mle/mle-actions';
import { MODULE_NAME_ACTIVITY } from '../../helpers/constants';

export const initialState = {
  activityStatus: ACTIVITY_UNATTEMPTED,
  activityState: null,
  isChecking: false,
  isCompleted: false,
  isAssigned: false,
  isCheckingStatus: false,
  activityResult: {}
};

export default (state = initialState, action) => {
  const cloned = _.clone(state);
  switch (action.type) {
    case ACTIVITY_STATUS_SET: {
      cloned.activityStatus = action.status;
      if (action.activityData) {
        const result = _.get(action.activityData, 'meta.user.result', {});
        cloned.activityResult = result;
      }
      return cloned;
    }
    case ACTIVITY_STATE_SET: {
      cloned.activityState = action.state;
      return cloned;
    }
    case INIT_MLE_DATA_REQUESTED:
    case SAVE_SEGMENT_REQUEST_INITIATED: {
      cloned.isChecking = true;
      return cloned;
    }
    case INIT_MLE_DATA_FAILED:
    case SAVE_SEGMENT_REQUEST_COMPLETED: {
      cloned.isChecking = false;
      return cloned;
    }

    case INIT_MLE_DATA_ACQUIRED: {
      const activityData = action.payload.find(item => item.meta.name === MODULE_NAME_ACTIVITY);
      const hasChecked = _.get(activityData, 'data.activityStatus') === ACTIVITY_MARKED;
      cloned.isChecking = false;
      if (hasChecked) {
        cloned.activityStatus = ACTIVITY_MARKED;
      }
      return cloned;
    }

    case ASSIGNMENT_STATUS_REQUESTED: {
      cloned.isCheckingStatus = true;
      return cloned;
    }

    case ASSIGNMENT_STATUS_FAILED: {
      cloned.isCheckingStatus = false;
      return cloned;
    }

    case ASSIGNMENT_STATUS_ACQUIRED: {
      const statusData = action.payload;
      cloned.isCompleted = _.get(statusData, 'isCompleted');
      cloned.isAssigned = _.get(statusData, 'isAssigned');
      cloned.isCheckingStatus = false;

      return cloned;
    }

    default:
      return state;
  }
};
