export const ACTIVITY_STATUS_SET = 'ACTIVITY_STATUS_SET';
export const ACTIVITY_STATE_SET = 'ACTIVITY_STATE_SET';

export const NON_MARKING_ACTIVITY = 'NON_MARKING_ACTIVITY';
export const ACTIVITY_UNATTEMPTED = 'ACTIVITY_UNATTEMPTED';
export const ACTIVITY_READY_FOR_MARKING = 'ACTIVITY_READY_FOR_MARKING';
export const ACTIVITY_MARKED = 'ACTIVITY_MARKED';

export const SAVE_SEGMENT_REQUEST_INITIATED = 'SAVE_SEGMENT_REQUEST_INITIATED';
export const SAVE_SEGMENT_REQUEST_COMPLETED = 'SAVE_SEGMENT_REQUEST_COMPLETED';

export const setActivityStatus = (status, activityData) => ({
  type: ACTIVITY_STATUS_SET,
  status,
  activityData
});

export const setActivityState = state => ({
  type: ACTIVITY_STATE_SET,
  state
});

export const saveSegmentRequested = () => ({
  type: SAVE_SEGMENT_REQUEST_INITIATED
});

export const saveSegmentCompleted = () => ({
  type: SAVE_SEGMENT_REQUEST_COMPLETED
});
