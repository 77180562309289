exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".image-modal---3D24B{display:flex;flex-direction:column;align-items:center;padding:20px 20px 0}.image-modal---3D24B .button---g4muU{margin-top:20px;height:30px}", ""]);

// exports
exports.locals = {
	"image-modal": "image-modal---3D24B",
	"button": "button---g4muU"
};