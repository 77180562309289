import React from 'react';
import getQueryString from 'querystring';
import _ from 'lodash';
import Resources from '../../helpers/resources';
import API from '../../helpers/api';
import { saveSegmentRequested, saveSegmentCompleted } from '../activity/activity-actions';
import { setUserRole } from '../user/user-actions';
import {
  userRoles,
  timestamps,
  proveletStates,
  MODULE_NAME_PROVELET,
  MODULE_TYPE_DEFAULT
} from '../../helpers/constants';
import { activateError } from '../error-handling/error-handling-actions';
import UnassignedModal from '../footer/unassigned-modal';
import { constructModal } from '../modal/modal-actions';
import { getStartTimeOnline, getTimeOnline } from './mle-utils';
import { PROVELET_DATA_ACQUIRED } from '../provelet/provelet-actions';
import { getModuleData } from '../../helpers/utils';
import { getNextFlags, ACTIONS } from '../../helpers/get-next-flags/get-next-flags';

export const QUERY_STRING_ACQUIRED = 'QUERY_STRING_ACQUIRED';
export const INIT_MLE_DATA_ACQUIRED = 'INIT_MLE_DATA_ACQUIRED';
export const INIT_MLE_DATA_REQUESTED = 'INIT_MLE_DATA_REQUESTED';
export const INIT_MLE_DATA_FAILED = 'INIT_MLE_DATA_FAILED';

export const TIMESTAMP_ENTER = 'TIMESTAMP_ENTER';
export const TIMESTAMP_LEAVE = 'TIMESTAMP_LEAVE';
export const TIMESTAMP_CHECK = 'TIMESTAMP_CHECK';
export const TIMESTAMP_COMPLETE = 'TIMESTAMP_COMPLETE';

export const ASSIGNMENT_STATUS_ACQUIRED = 'ASSIGNMENT_STATUS_ACQUIRED';
export const ASSIGNMENT_STATUS_REQUESTED = 'ASSIGNMENT_STATUS_REQUESTED';
export const ASSIGNMENT_STATUS_FAILED = 'ASSIGNMENT_STATUS_FAILED';

export const COMPONENT_ENABLED_SPINNER = 'COMPONENT_ENABLED_SPINNER';
export const COMPONENT_DISABLED_SPINNER = 'COMPONENT_DISABLED_SPINNER';

export const DEFAULT_ASSIGNMENT_SPINNER = 'DEFAULT_ASSIGNMENT_SPINNER';
export const ACTIVITY_RENDER_SPINNER = 'ACTIVITY_RENDER_SPINNER';

export const START_TIME_RECORDED = 'START_TIME_RECORDED';

export const enableSpinner = item => ({
  type: COMPONENT_ENABLED_SPINNER,
  item
});

export const disableSpinner = item => ({
  type: COMPONENT_DISABLED_SPINNER,
  item
});

export const queryString = () => {
  const queryParams = window.location.search;
  const query = queryParams.replace('?', '');
  return getQueryString.parse(query);
};

export const acquireQueryString = () => (dispatch) => {
  const { girlId, userId, isPreview, locale, authToken, newContent, keypadPreset, assignmentId = 0 } = queryString();
  dispatch(setUserRole(userId && isPreview !== 'true' ? userRoles.TEACHER : userRoles.STUDENT));
  dispatch({
    type: QUERY_STRING_ACQUIRED,
    payload: {
      girlId,
      userId,
      isPreview: isPreview === 'true',
      locale,
      authToken,
      newContent,
      keypadPreset,
      assignmentId
    }
  });
};

const acquireInitMleDataRequested = () => ({
  type: INIT_MLE_DATA_REQUESTED
});

const acquireInitMleDataFailed = () => ({
  type: INIT_MLE_DATA_FAILED
});

export const acquireInitMleData = () => async (dispatch, getState) => {
  const { userReducer: { userRole }, queryStringReducer: { userId, girlId, isPreview, assignmentId } } = getState();

  dispatch(acquireInitMleDataRequested());

  try {
    const response = await API.getModules({ userId, girlId, assignmentId });
    if (response.data) {
      dispatch({
        type: INIT_MLE_DATA_ACQUIRED,
        payload: response.data
      });
      if (!girlId || isPreview) return;
      const proveletData = getModuleData(response, MODULE_NAME_PROVELET);
      const flags = _.get(proveletData, 'meta.flags', proveletStates.DEFAULT);
      let data = _.get(proveletData, 'data', []);
      if (!Array.isArray(data)) {
        data = [];
      }
      const nextFlags = getNextFlags({
        user: userRole,
        action: ACTIONS.VIEW,
        flags
      });
      if (flags === nextFlags) {
        dispatch({
          type: PROVELET_DATA_ACQUIRED,
          data,
          flags
        });
      } else {
        const saveResponse = await API.saveModules({
          userId,
          moduleName: MODULE_NAME_PROVELET,
          moduleType: MODULE_TYPE_DEFAULT,
          girlId,
          flags,
          // Endpoint requires an empty object
          payload: {},
          user: userRole,
          action: ACTIONS.VIEW,
          assignmentId
        });

        if (saveResponse.status === 200 || saveResponse.status === 204) {
          dispatch({
            type: PROVELET_DATA_ACQUIRED,
            data: saveResponse.data.data,
            flags: saveResponse.data.meta.flags
          });
        } else {
          dispatch(activateError(response.status));
        }
      }
    } else {
      dispatch(acquireInitMleDataFailed());
      dispatch(activateError(response.status));
    }
  } catch (err) {
    dispatch(acquireInitMleDataFailed());
    dispatch(activateError(err));
    throw err;
  }
};

export const saveSegment = ({
  content,
  girlId,
  moduleType,
  savedCallBack,
  moduleName,
  assignmentId
}) => async (dispatch) => {
  try {
    dispatch(saveSegmentRequested());
    const response = await API.saveModules({
      moduleName,
      moduleType,
      girlId,
      flags: 0,
      payload: content,
      assignmentId
    });

    if (response.status === 200 || response.status === 204) {
      dispatch(saveSegmentCompleted());
      savedCallBack(response.data);
    } else {
      dispatch(saveSegmentCompleted());
      dispatch({
        type: 'ERROR_DETECTED',
        payload: response.data
      });
    }
  } catch (err) {
    dispatch(saveSegmentCompleted());
    dispatch({
      type: 'ERROR_DETECTED',
      payload: err
    });
  }
};

export const completeActivity = ({
  completeCallback = () => { }
}) => async (dispatch, getState) => {
  try {
    dispatch(saveSegmentRequested());

    const { activityReducer, queryStringReducer } = getState();
    const { girlId, assignmentId } = queryStringReducer;

    if (!activityReducer.isCompleted) {
      const params = {
        productId: window._env_.ProductId || 1,
        resourceId: girlId
      };
      const statusResponse = await Resources.getActivityStatus
        .get(params)
        .send();

      if (!(statusResponse.status === 200 || statusResponse.status === 204)) {
        dispatch(activateError(statusResponse.status));
      }

      const isActivityAssigned = _.get(statusResponse, 'data.isAssigned');

      if (!isActivityAssigned) {
        dispatch(constructModal({
          activate: true,
          content: <UnassignedModal />,
          canvasOnClick: false
        }));
      } else {
        const response = await Resources.completeActivity
          .post()
          .withBody({
            girlId,
            assignmentId
          })
          .send();

        if (response.status === 200 || response.status === 204) {
          dispatch(saveSegmentCompleted());
          completeCallback();
        } else {
          dispatch(saveSegmentCompleted());
          dispatch({
            type: 'ERROR_DETECTED',
            payload: response.data
          });
        }
      }
    } else {
      completeCallback();
    }
  } catch (err) {
    dispatch(saveSegmentCompleted());
    dispatch({
      type: 'ERROR_DETECTED',
      payload: err
    });
    throw err;
  }
};

export const recordTime = verb => async (dispatch, getState) => {
  const { userRole } = getState().userReducer;
  const { isPreview, userId, girlId } = queryString();
  let eventId = null;

  switch (verb) {
    case TIMESTAMP_ENTER: {
      if (isPreview) {
        eventId = timestamps.TEACHER_ENTERED_PREVIEW;
      } else if (userRole === userRoles.STUDENT) {
        eventId = timestamps.STUDENT_ENTERED;
        // record landed time
        dispatch({
          type: START_TIME_RECORDED,
          startTime: getStartTimeOnline()
        });
      } else if (userRole === userRoles.TEACHER) {
        eventId = timestamps.TEACHER_ENTERED;
      }
      break;
    }
    case TIMESTAMP_LEAVE: {
      if (isPreview) {
        eventId = timestamps.TEACHER_LEFT_PREVIEW;
      } else if (userRole === userRoles.STUDENT) {
        eventId = timestamps.STUDENT_LEFT;
      } else if (userRole === userRoles.TEACHER) {
        eventId = timestamps.TEACHER_LEFT;
      }
      break;
    }
    case TIMESTAMP_CHECK: {
      if (isPreview || userId) {
        break;
      }
      eventId = timestamps.STUDENT_CHECKED_ACTIVITY;
      break;
    }
    case TIMESTAMP_COMPLETE: {
      if (isPreview || userId) {
        break;
      }
      eventId = timestamps.STUDENT_COMPLETED_ACTIVITY;
      break;
    }
    default: {
      break;
    }
  }

  try {
    const response = await Resources.events
      .post()
      .withBody({
        eventId,
        resourceId: girlId
      })
      .withParams(getTimeOnline(eventId, getState().timeOnlineReducer.startTime))
      .send();

    if (response.status === 200 || response.status === 204) {
      // eslint-disable-next-line no-console
      // console.log(`Time recorded - ${eventId}`);
    } else {
      // eslint-disable-next-line no-console
      // console.log('Timestamp error');
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    // console.log(`Timestamp error - ${err}`);
  }
};

const acquireAssignmentStatusRequested = () => ({
  type: ASSIGNMENT_STATUS_REQUESTED
});

const acquireAssignmentStatusFailed = () => ({
  type: ASSIGNMENT_STATUS_FAILED
});

export const acquireAssignmentStatus = () => async (dispatch) => {
  dispatch(acquireAssignmentStatusRequested());

  const { girlId } = queryString();

  try {
    const params = {
      productId: window._env_.ProductId || 1,
      resourceId: girlId
    };
    const response = await Resources.getActivityStatus
      .get(params)
      .send();

    if (!(response.status === 200 || response.status === 204)) {
      dispatch(acquireAssignmentStatusFailed());
      dispatch(activateError(response.status));
    }

    if (response.data) {
      dispatch({
        type: ASSIGNMENT_STATUS_ACQUIRED,
        payload: response.data
      });
    } else {
      dispatch(acquireAssignmentStatusFailed());
      dispatch(activateError(response.status));
    }
  } catch (err) {
    dispatch(acquireAssignmentStatusFailed());
    dispatch({
      type: 'ERROR_DETECTED',
      payload: err
    });
    throw err;
  }
};

export const registerDefaultAssign = ({
  teacherUserProfileId,
  girlId }) => async (dispatch) => {
  enableSpinner(DEFAULT_ASSIGNMENT_SPINNER);
  try {
    const response = await Resources.assignDefault
      .post()
      .withBody({
        teacherUserProfileId,
        girlId
      })
      .send();

    const { data, status } = response;

    if (status >= 200 && status < 300) {
      disableSpinner(DEFAULT_ASSIGNMENT_SPINNER);
    } else {
      disableSpinner(DEFAULT_ASSIGNMENT_SPINNER);
      dispatch(activateError(data.status));
    }
  } catch (err) {
    disableSpinner(DEFAULT_ASSIGNMENT_SPINNER);
    dispatch(activateError(err));
  }
};
