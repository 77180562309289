/* global window document */
/* eslint-disable no-console */

import React from 'react';
import { createRoot } from 'react-dom/client';
import thunk from 'redux-thunk';
import queryString from 'querystring';
import { createStore, applyMiddleware } from 'redux';

import { handleDefaults } from '../../helpers/utils';
import CombinedReducers from './combined-reducers';
import Resources from '../../helpers/resources';
import { getI18N } from '../../helpers/i18n/get-i18n';


const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

export default class BasicApp {
  constructor(element, dynamicOptions) {
    BasicApp.configure();

    this.element = element;
    this.options = handleDefaults({}, dynamicOptions);

    this.renderElement();
  }

  render() {
    return <h1>You should define a render() method for your subclass of BasicApp</h1>;
  }


  // Implementation details.  You should not need to change any code past this point.

  renderElement() {
    const store = createStoreWithMiddleware(
      CombinedReducers,
      // eslint-disable-next-line no-underscore-dangle
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    );

    const locales = linguiLocales();

    const root = createRoot(document.querySelector(this.element));

    getI18N(window._env_.LocalisationCdnBaseUrl, locales)
      .then((i18n) => {
        root.render(this.render(store, i18n));
      });

    function linguiLocales() {
      const query = window.location.search.slice(1);
      const match = query.match(/\blocale=([-a-zA-Z]+)\b/);
      const locale = match ? match[1] : 'en-AU';

      return [locale];
    }
  }

  static configure() {
    // How we want to configure things depends on where this app is running,
    // and where the services we want to access live.
    //
    // 1. When running on a developer's machine, using a mock server, we want
    //    to use a hard-coded config from
    //
    //       helpers/local-resource-config.js
    //
    // 2. When running on a developer's machine, but we want to use back-end
    //    services on demo, QA or live, we want to get the configuration from
    //    the configuration of the host app.
    //
    // 3. When running on demo, QA or live, the configuration will be injected
    //    based on the host app's configuration, but can be overridden by query
    //    parameters.
    //
    // We determine which situation we have by looking at the window location
    // URL and the query parameters, and whether there is a pppAppConfig property
    // on the window object.

    const location = window.location;
    const queryParams = location.search;
    const query = queryParams.replace('?', '');
    const params = queryString.parse(query);

    BasicApp.configureForRemoteHost(params);
  }

  static configureForRemoteHost(params) {
    const envName = 'live';
    const credentials = BasicApp.getCredentials(params);

    if (BasicApp.weHaveUsableCredentials(credentials)) {
      Resources.initialise(envName, credentials);
    }
  }

  static getCredentials(params) {
    const credentials = {};
    const items = ['authToken', 'username', 'password'];

    for (const paramName in params) {
      const value = params[paramName];

      for (const item of items) {
        if (paramName.toLowerCase() === item.toLowerCase()) {
          credentials[item] = value;
        }
      }
    }

    return credentials;
  }

  static weHaveUsableCredentials(credentials) {
    let usableCredentials = false;

    if (credentials.authToken) {
      usableCredentials = true;
    } else if (credentials.username && credentials.password) {
      usableCredentials = true;
    }

    if (!usableCredentials) {
      console.error('EntryApp.configureForDeveloperMachine() - to execute, we need either an authToken or' +
        ' a username/password pair.  After combining data from window.pppAppConfig and the query' +
        ' parameters, I still do not have what I need. The app will probably not function correctly if' +
        ' it requires any 3P resources.');
    }

    return usableCredentials;
  }
}
