exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".scan-edit---1NWMV{width:100%;height:calc(100% - 75px);position:fixed;top:75px;left:0;z-index:99;background-color:#fff;text-align:center}.scan-edit---1NWMV .scan-header---3XvP8{font-size:24px;color:#fff;line-height:50px}.scan-edit---1NWMV .hidden---yVJGR{display:none !important}.scan-edit---1NWMV .camera-area---2bpWy{display:flex;height:calc(100% - 135px)}.scan-edit---1NWMV .camera-area---2bpWy .green---1lOSa{height:100%;width:calc((100% - 1.3333333333*(100vh - 135px))/2);background-repeat:no-repeat;background-size:auto 100%}.scan-edit---1NWMV .camera-area---2bpWy .green-left---3IBsi{background-position:right}.scan-edit---1NWMV .camera-area---2bpWy .green-right---3s8m0{background-position:left}.scan-edit---1NWMV .camera-area---2bpWy canvas{width:calc(1.3333333333*(100vh - 135px));margin:0 auto;border:2px dashed;box-sizing:content-box}.scan-edit---1NWMV .camera-area---2bpWy .has-capture---2MOox{border:3px solid green}.scan-edit---1NWMV .scan-footer---23jlX{width:calc(1.3333333333*(100vh - 135px));height:85px;margin:0 auto;display:flex;justify-content:space-between;padding-top:25px}.scan-edit---1NWMV .scan-footer---23jlX .btn-print---3wTKk{display:flex;align-items:center;justify-content:center}.scan-edit---1NWMV .scan-footer---23jlX .btn-print---3wTKk svg{width:25px;height:25px;vertical-align:middle;margin-left:.5em}.scan-edit---1NWMV .scan-footer---23jlX button{min-width:130px}.scan-edit---1NWMV .scan-overlay---1N5lp{position:absolute;top:0;left:0;width:100%;height:100%;background-color:#00abe8;z-index:-1}.scan-edit---1NWMV .btn-retake---ZamJ7{transform:translate(-50%, -80%);position:absolute;left:50%;height:40px;padding:0 20px}.scan-view---3o2rT{width:100%;text-align:center;cursor:pointer;background-repeat:no-repeat;background-size:contain;background-position:center;min-height:250px}", ""]);

// exports
exports.locals = {
	"scan-edit": "scan-edit---1NWMV",
	"scan-header": "scan-header---3XvP8",
	"hidden": "hidden---yVJGR",
	"camera-area": "camera-area---2bpWy",
	"green": "green---1lOSa",
	"green-left": "green-left---3IBsi",
	"green-right": "green-right---3s8m0",
	"has-capture": "has-capture---2MOox",
	"scan-footer": "scan-footer---23jlX",
	"btn-print": "btn-print---3wTKk",
	"scan-overlay": "scan-overlay---1N5lp",
	"btn-retake": "btn-retake---ZamJ7",
	"scan-view": "scan-view---3o2rT"
};