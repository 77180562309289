import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CanvasDraw from 'react-canvas-draw';
import Measure from 'react-measure';
import _ from 'lodash';
import classNames from 'classnames';
import css from './scratchpad-view.scss';

const ScratchpadView = (props) => {
  const [size, setSize] = useState(null);
  const [canvasWidth, setCanvasWidth] = useState(200);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const { background, canvas, isGeoGebra } = props.data;

  useEffect(() => {
    setSize(containerRef.current.offsetWidth);
  });

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.clear();
      // the timeout lets the canvas finish clearing the existing scribble before redraw
      setTimeout(() => {
        canvasRef.current.loadSaveData(canvas, true);
      });
    }
  }, [canvasWidth]);

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        const updatedCanvasWidth = _.get(contentRect, 'bounds.width', 0);
        if (updatedCanvasWidth) {
          setCanvasWidth(updatedCanvasWidth);
        }
      }}
    >
      {({ measureRef }) => (
        <div ref={measureRef}>
          <div className={css['drawing-area']} ref={containerRef} >
            {size && !isGeoGebra ?
              <CanvasDraw
                className={classNames(css[background], css.canvas)}
                canvasWidth={canvasWidth}
                canvasHeight={canvasWidth * 0.5}
                hideGrid
                saveData={canvas}
                immediateLoading
                disabled
                ref={canvasRef}
              />
              : null
            }
            {isGeoGebra && <img className={css.image} src={`data:image/png;base64, ${canvas}`} alt="" />}
          </div>
        </div>
      )}
    </Measure>
  );
};

ScratchpadView.propTypes = {
  data: PropTypes.object
};

export default ScratchpadView;
