import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import arrowImage from '../../assets/images/icon_end.svg';
import css from './provelet.scss';
import { BtnIcon } from '../../components/buttons';
import { toggleSlideLeft } from './provelet-actions';
import { userTypes, userRoles } from '../../helpers/constants';
import WidgetsView from '../widgets-view/widgets-view';
import Triangle from '../../components/triangle/triangle';
import WidgetMenu from '../widget-menu/widget-menu';
import WidgetEditor from '../widget-editor/widget-editor';
import DateHeader from '../date-header/date-header';
import MyWorkbook from '../my-workbook/my-workbook';

const Provelet = () => {
  const dispatch = useDispatch();
  const { proveletReducer, userReducer, widgetMenuReducer } = useSelector(state => state);

  const date = new Date();
  const prevDate =
    proveletReducer.data.length > 0 ? new Date(proveletReducer.data[proveletReducer.data.length - 1].createdUTC) : null;

  const contentEndRef = useRef(null);
  const hideAskTeacher = (userReducer.userType === userTypes.HOMESTUDENT && userReducer.userRole !== userRoles.TEACHER);

  useEffect(() => {
    // allow time for widgets to render before scrolling
    const interval = setTimeout(() => {
      contentEndRef.current.scrollIntoView({ behavior: 'smooth' });
    });
    return () => {
      clearTimeout(interval);
    };
  }, [proveletReducer.data.length, proveletReducer.isLeftSlided, proveletReducer.isEditing, widgetMenuReducer]);

  return (
    <div className={`${css.provelet} ${proveletReducer.isLeftSlided ? css['slide-left'] : ''}`}>
      <BtnIcon
        icon={arrowImage}
        className={css['slide-btn']}
        callBack={() => dispatch(toggleSlideLeft(!proveletReducer.isLeftSlided))}
      />
      <div className={css['outer-radius']}>
        <div className={css.content}>
          <MyWorkbook />
          <WidgetsView user={userReducer.userRole} data={proveletReducer.data} />
          <DateHeader date={date} prevDate={prevDate} />
          {proveletReducer.isEditing ?
            <WidgetEditor user={userReducer.userRole} /> : <WidgetMenu hideAskTeacher={hideAskTeacher} />}
          <div className={css.end} ref={contentEndRef} />
        </div>
      </div>
      <Triangle
        className={css.tri}
        leftPx={'0'}
        rightPx={'8'}
        topPx={'10'}
        color={'white'}
      />
    </div>
  );
};

export default Provelet;
