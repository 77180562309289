import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDevToolsStatus } from './devtools-detect-actions';
import { queryString } from '../mle/mle-actions';
import { resetActivity } from '../ducs/ducs-actions';
import { removeLearnosityKeypad } from '../../helpers/utils';

const DetectDevtools = () => {
  const dispatch = useDispatch();
  let isDevToolsOpened = true;
  let oldDevToolsOpenState = true;

  const dummyElement = window.document.createElement('span');
  Object.defineProperty(dummyElement, 'id', {
    get: () => {
      isDevToolsOpened = true;
    }
  });

  const checkIfDevToolsIsOpen = () => {
    isDevToolsOpened = false;
    // do not remove the console statement below, it does the trick of detecting the devtools
    console.log(dummyElement); // eslint-disable-line no-console
    if (oldDevToolsOpenState !== isDevToolsOpened) {
      oldDevToolsOpenState = isDevToolsOpened;
      dispatch(setDevToolsStatus(isDevToolsOpened));
      dispatch(resetActivity());
      if (isDevToolsOpened) {
        // remove learnosity keypad from DOM as unmounting DUCS will not get rid of it
        removeLearnosityKeypad();
      }
    }
    window.requestAnimationFrame(checkIfDevToolsIsOpen);
  };

  const startDetection = async () => {
    const hasDetection = queryString().nihonium !== 'ph03n1x';
    if (hasDetection && (window._env_.hasCheatDetection === 'yes' && true)) {
      window.requestAnimationFrame(checkIfDevToolsIsOpen);
    } else {
      dispatch(setDevToolsStatus(false));
    }
  };

  useEffect(() => {
    startDetection();
  }, []);

  return null;
};

export default DetectDevtools;
