exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".button---2wN9Z{border-radius:30px;background-color:#3477c7;padding:0 40px;border:none;color:#fff;font-size:16px;font-weight:bold;height:50px;outline:none}@media(hover: hover){.button---2wN9Z:hover{background-color:#fd7849}}.button---2wN9Z:active{background-color:#b2e1e3}.button---2wN9Z:disabled{background-color:#3477c7;opacity:.5}.small---3X-K8{padding:0 15px;height:30px;font-size:14px}", ""]);

// exports
exports.locals = {
	"button": "button---2wN9Z",
	"small": "small---3X-K8"
};