import React from 'react';
import css from './loading.scss';

const Loading = () => (
  <div className={css.loading}>
    <div className={`${css['ball-loader-ball']} ${css.ball1}`} />
    <div className={`${css['ball-loader-ball']} ${css.ball2}`} />
    <div className={`${css['ball-loader-ball']} ${css.ball3}`} />
  </div>
);

export default Loading;
