import _ from 'lodash';
import {
  GEOGEBRA_URL_ACQUIRED,
  GEOGEBRA_SCRIPT_LOADED
} from './scratchpad-edit-actions';

export const initialState = {
  libraryUrl: null,
  isScriptLoaded: false
};

export default (state = initialState, action) => {
  const cloned = _.clone(state);
  switch (action.type) {
    case GEOGEBRA_URL_ACQUIRED: {
      cloned.libraryUrl = action.geogebraLibraryUrl;
      return cloned;
    }

    case GEOGEBRA_SCRIPT_LOADED: {
      cloned.isScriptLoaded = action.isLoaded;
      return cloned;
    }

    default:
      return state;
  }
};
