import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { AudioPlayer } from '../../audio-controller';
import css from './record-audio-view.scss';
import API from '../../../helpers/api';

const RecordAudioView = ({ data }) => {
  const [audioUrl, setAudioUrl] = useState(data.audioUrl);
  const { girlId, userId, assignmentId } = useSelector(state => state.queryStringReducer);

  const updateAudioUrl = async () => {
    setAudioUrl(
      await API.getResourceUrl({ userId, girlId, guid: data.audioId, assignmentId })
    );
  };

  useEffect(() => {
    if (data.audioId) {
      updateAudioUrl();
    }
    return () => {
      window.URL.revokeObjectURL(audioUrl);
    };
  }, []);

  return (
    <div className={css['record-audio-view']}>
      {
        audioUrl ? (
          <AudioPlayer src={audioUrl} />
        ) : (
          <p><Trans>No audio recorded</Trans></p>
        )
      }
    </div>
  );
};

RecordAudioView.propTypes = {
  data: PropTypes.object
};

export default RecordAudioView;
