import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InlineSVG from 'react-inlinesvg';
import Bowser from 'bowser';
import { t } from '@lingui/macro';
import iconEnvelope from '../../assets/images/icon_ask_circle.svg';
import iconWrite from '../../assets/images/icon_write.svg';
import iconRecord from '../../assets/images/ico_audio.svg';
import css from './ask-teacher.scss';
import { activateBtn, TEACHER_BTN } from '../widget-menu/widget-menu-actions';
import { widgetEdit } from '../provelet/provelet-actions';
import { userRoles } from '../../helpers/constants';
import { constructModal, activateModal } from '../modal/modal-actions';
import BrowserSupportModal from '../browser-support-modal/browser-support-modal';
import { useLocalisation } from '../../helpers/i18n/use-localisation';

const { STUDENT, TEACHER } = userRoles;

const AskTeacher = ({
  className
}) => {
  const { i18n } = useLocalisation();
  const dispatch = useDispatch();
  const widgetMenuReducer = useSelector(state => state.widgetMenuReducer);
  const { userRole } = useSelector(state => state.userReducer);
  const mainBtn = {
    [STUDENT]: i18n._(t`Message teacher`),
    [TEACHER]: i18n._(t`Message student`)
  };
  const btns = {
    [STUDENT]: [
      {
        id: 'Text',
        label: i18n._(t`Write question`),
        icon: iconWrite
      },
      {
        id: 'RecordAudio',
        label: i18n._(t`Record question`),
        icon: iconRecord
      }
    ],
    [TEACHER]: [
      {
        id: 'Text',
        label: i18n._(t`Write reply`),
        icon: iconWrite
      },
      {
        id: 'RecordAudio',
        label: i18n._(t`Record reply`),
        icon: iconRecord
      }
    ]
  };

  const handleBtn = (id) => {
    if (id === 'RecordAudio') {
      let action;
      let issue;
      let showModal = false;
      const browser = Bowser.getParser(window.navigator.userAgent);
      const platformType = browser.getPlatformType();
      if ((platformType === 'mobile' || platformType === 'tablet') && !browser.getBrowserVersion()) {
        issue = i18n._(t`Recording is not available on this App`);
        showModal = true;
      } else {
        issue = i18n._(t`Recording is not working`);
        if (browser.satisfies({ ie: '<=11' })) {
          action = i18n._(t`Use a different browser to Internet Explorer`);
          showModal = true;
        } else if (browser.satisfies({ safari: '<=10' })) {
          action = i18n._(t`Update your Safari browser`);
          showModal = true;
        }
      }
      if (showModal) {
        dispatch(constructModal({
          activate: true,
          content: (
            <BrowserSupportModal
              issue={issue}
              action={action}
              handleOk={() => dispatch(activateModal(false))}
            />
          ),
          canvasOnClick: false
        }));
        return;
      }
    }
    dispatch(widgetEdit(id));
  };

  return (
    <div
      className={`${css['ask-teacher']} ${css[widgetMenuReducer[TEACHER_BTN]]} ${className || ''}`}
      onClick={() => dispatch(activateBtn(TEACHER_BTN))}
      role="presentation"
    >
      {
        widgetMenuReducer[TEACHER_BTN] === '' ||
          widgetMenuReducer[TEACHER_BTN] === 'contract' ?
          (
            <div className={css['btn-elm']} title={mainBtn[userRole]}>
              <InlineSVG className={css.icon} src={iconEnvelope} />
              <p className={css.text}>{mainBtn[userRole]}</p>
            </div>
          ) : (
            <ul className={css.btns}>
              {
                btns[userRole].map(btn => (
                  <li
                    key={btn.id}
                    className={css.btn}
                    role="presentation"
                    onClick={() => handleBtn(btn.id)}
                  >
                    <InlineSVG className={css['btn-icon']} src={btn.icon} />
                    <p className={css['btn-text']}>{btn.label}</p>
                  </li>
                ))
              }
            </ul>
          )
      }

    </div>
  );
};

AskTeacher.propTypes = {
  className: PropTypes.string
};

export default AskTeacher;
