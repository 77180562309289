exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".ducs---1FvKJ{display:block}.ducs---1FvKJ *{font-family:\"muli\",\"san-serif\"}.ducs---1FvKJ img{display:unset}.ppp-ducs-sticky-column{display:none}.lrn_widget .lrn-formula-keyboard{transform:scale(0.75);transform-origin:top left}.lrn_widget .lrn-ui-style-floating-keyboard div.lrn-drag-area{height:30px;border-radius:5px 5px 0 0;background-color:#3477c7}.lrn_widget .lrn-ui-style-floating-keyboard div.lrn-drag-area span{background:#fff;margin:2px}.lrn_widget .lrn_toolbar.lrn-formula-keyboard-menu{height:30px;min-height:30px}.lrn_widget .lrn_toolbar.lrn-formula-keyboard-menu .clearfix{display:none}.lrn_widget .lrn-keyboard-holder{background-color:#eaeef0;padding:1px}.lrn_widget .lrn-keyboard-holder button.lrn-btn-placeholder{background:#eaeef0}.lrn_widget button.lrn_btn_grid{background-color:#bdcdd4;border:3px solid #eaeef0;border-radius:5px;line-height:25px;font-weight:bold}.lrn_widget button.lrn_btn_grid.lrn-btn-grid-dir{background-color:#f08c0e}.lrn_widget button.lrn_btn_grid.lrn-btn-grid-dir .lrn_arrow_left,.lrn_widget button.lrn_btn_grid.lrn-btn-grid-dir .lrn_arrow_right,.lrn_widget button.lrn_btn_grid.lrn-btn-grid-dir .lrn_backspace{font-size:30px;line-height:30px;padding-top:2px}.lrn_widget button.lrn_btn_grid.lrn-btn-grid-operator,.lrn_widget button.lrn_btn_grid.lrn-btn-grid-sym{background-color:#4da3cb}.lrn_widget button.lrn_btn_grid:focus,.lrn_widget button.lrn_btn_grid:hover{background-color:#3477c7 !important;color:#fff !important}.lrn_widget button.lrn_btn_grid:focus::after,.lrn_widget button.lrn_btn_grid:hover::after{content:none !important}", ""]);

// exports
exports.locals = {
	"ducs": "ducs---1FvKJ"
};