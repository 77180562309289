exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".check-confirm-modal---34c_W{width:400px;padding:20px 20px 0;text-align:left;font-size:14px}.check-confirm-modal---34c_W h2{text-align:center;margin-bottom:10px;font-size:18px}.check-confirm-modal---34c_W .modal-buttons---3n8yf{display:flex;justify-content:space-between;margin-top:30px}.check-confirm-modal---34c_W .modal-buttons---3n8yf .button---_YdXh{height:30px;padding:0 15px}.check-confirm-modal---34c_W .modal-button---3YIX3{display:flex;justify-content:center;margin-top:30px}", ""]);

// exports
exports.locals = {
	"check-confirm-modal": "check-confirm-modal---34c_W",
	"modal-buttons": "modal-buttons---3n8yf",
	"button": "button---_YdXh",
	"modal-button": "modal-button---3YIX3"
};