exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".ask-teacher---1Igp9{background-color:#3477c7;margin-left:20px;border-radius:35px;width:calc(50% - 20px);cursor:pointer;overflow:hidden;display:flex;min-height:70px}.ask-teacher---1Igp9.expand---YoD-f{margin-left:0}.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML{width:50%}.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML:first-child{border-right:1px solid rgba(255,255,255,.2)}@media(hover: hover){.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML:hover{background-color:#b2e1e3}}.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML:active{background-color:#fd7849}.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML:disabled{background-color:#3477c7;opacity:.5}.ask-teacher---1Igp9.contract---1F5wr{width:70px;height:70px;transition:width .5s ease-out}.ask-teacher---1Igp9.contract---1F5wr .text---m3sDT{display:none}.ask-teacher---1Igp9.expand---YoD-f{width:calc(100% - 80px);transition:width .5s ease-out}.ask-teacher---1Igp9 .btn-elm---2wTHt{height:inherit;display:flex;align-items:center}.ask-teacher---1Igp9 .btn-elm---2wTHt svg{width:40px;height:40px}.ask-teacher---1Igp9 .icon---3GBnc{width:40px;height:40px;display:block;margin-left:15px;padding-left:0;flex-shrink:0;pointer-events:none}@media screen and (-webkit-min-device-pixel-ratio: 0){.ask-teacher---1Igp9 .icon---3GBnc{margin-left:0;padding-left:15px}}.ask-teacher---1Igp9 .text---m3sDT{color:#fff;font-weight:bold;margin-left:20px;font-size:16px}@media screen and (-webkit-min-device-pixel-ratio: 0){.ask-teacher---1Igp9 .text---m3sDT{margin-left:30px}}.ask-teacher---1Igp9 .btns---20rK-{display:flex;flex-wrap:wrap;height:inherit;align-items:center;width:100%}.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML{color:#fff;display:flex;align-items:center;height:70px;overflow:hidden}.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML .btn-icon---6YJLf svg{display:block;margin:0 15px}.ask-teacher---1Igp9 .btns---20rK- .btn---2tVML .btn-text---2-UTL{font-size:16px;font-weight:bold}", ""]);

// exports
exports.locals = {
	"ask-teacher": "ask-teacher---1Igp9",
	"expand": "expand---YoD-f",
	"btns": "btns---20rK-",
	"btn": "btn---2tVML",
	"contract": "contract---1F5wr",
	"text": "text---m3sDT",
	"btn-elm": "btn-elm---2wTHt",
	"icon": "icon---3GBnc",
	"btn-icon": "btn-icon---6YJLf",
	"btn-text": "btn-text---2-UTL"
};