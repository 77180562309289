export const BTN_ACTIVATED = 'BTN_ACTIVATED';
export const RESET_MENU = 'RESET_MENU';
export const WIDGET_BTN = 'widgetBtn';
export const TEACHER_BTN = 'teacherBtn';

export const activateBtn = btn => ({
  type: BTN_ACTIVATED,
  btn
});

export const resetMenu = () => ({
  type: RESET_MENU
});
