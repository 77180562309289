exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".record-audio-view---KD1i5{display:flex;justify-content:center}", ""]);

// exports
exports.locals = {
	"record-audio-view": "record-audio-view---KD1i5"
};