import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import styleable from 'react-styleable/lib/styleable';
import { Trans } from '@lingui/macro';
import { BtnText } from '../../components/buttons';
import css from './footer-modals.scss';

const CheckConfirmModal = ({ handleCheck, handleCancel }) => {
  const activityReducer = useSelector(state => state.activityReducer);
  return (
    <div data-testid="check-confirm-modal" className={css['check-confirm-modal']}>
      <h2><Trans>Attention!</Trans></h2>
      <p><Trans>You can only check your answer once, and you will not be able to make changes after this.</Trans></p>
      <br />
      <p><Trans>Questions that require you to show your work on the right side will not be checked,
        and will need a teacher review.</Trans></p>
      <div className={css['modal-buttons']}>
        <BtnText
          data-testid="cancel"
          className={css.button}
          callBack={handleCancel}
        >
          <Trans>Cancel</Trans>
        </BtnText>
        <BtnText
          data-testid="check"
          className={css.button}
          callBack={handleCheck}
          isDisabled={activityReducer.isChecking}
        >
          <Trans>Check</Trans>
        </BtnText>
      </div>
    </div>
  );
};

CheckConfirmModal.propTypes = {
  handleCancel: PropTypes.func,
  handleCheck: PropTypes.func
};

export default styleable(css)(CheckConfirmModal);
