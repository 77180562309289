import { t } from '@lingui/macro';

const tt = v => new StringProxy(v);

function StringProxy(value) { this.value = value; }
StringProxy.prototype.toString = function proxyToString() {
  return window.i18n._(this.value);
};

// Strands
export const NUMBER_ALGEBRA = 'number&algebra';
export const STATISTICS_DATA = 'statistics&data';
export const CHANCE_PROBABILITY = 'chance&probability';
export const GEOMETRY = 'geometry';
export const MEASUREMENT = 'measurement';

// Substrands
export const ADDITION_SUBTRACTION = 'addition&subtraction';
export const MULTIPLICATION_DIVISION = 'multiplication&division';
export const FRACTIONS = 'fractions';
export const DECIMALS = 'decimals';
export const PERCENTAGES = 'percentages';
export const INDICES = 'indices';
export const OPERATIONS = 'operations';
export const RATES_RATIOS = 'rates&ratios';
export const NUMBERTHEORY = 'numbertheory';
export const MONEY = 'money';
export const WHOLENUMBER = 'wholenumber';
export const PATTERNS = 'patterns';
export const EQUATIONS_EXPRESSIONS = 'equations&expressions';
export const LOGARITHMS = 'logarithms';
export const N_A_GENERAL = 'general';

export const THREEDSHAPE = '3dshape';
export const TWODSHAPE = '2dshape';
export const SYMMTRANS_LOCN = 'symmtrans&locn';
export const ANGLE = 'angle';
export const GEOMETRICPROOFS = 'geometricproofs';

export const TIME = 'time';
export const AREA = 'area';
export const LENGTH = 'length';
export const MASS = 'mass';
export const VOLUME_CAPACITY = 'volume&capacity';
export const TEMPERATURE = 'temperature';
export const PYTHAGORAS_TRIG = 'pythagoras&trig';
export const MEAS_GENERAL = 'general';

export const SUB_STRAND_COLOURS = {
  [NUMBER_ALGEBRA]: '#FF4949',
  [STATISTICS_DATA]: '#A14AD9',
  [CHANCE_PROBABILITY]: '#EA5100',
  [GEOMETRY]: '#0878A8',
  [MEASUREMENT]: '#4B8400'
};

export const strands = {
  [NUMBER_ALGEBRA]: {
    title: tt(t`Number & Algebra`),
    colour: '#E27B7B',
    substrands: [
      ADDITION_SUBTRACTION,
      MULTIPLICATION_DIVISION,
      FRACTIONS,
      DECIMALS,
      PERCENTAGES,
      INDICES,
      OPERATIONS,
      RATES_RATIOS,
      NUMBERTHEORY,
      MONEY,
      WHOLENUMBER,
      PATTERNS,
      EQUATIONS_EXPRESSIONS,
      LOGARITHMS,
      N_A_GENERAL
    ]
  },
  [STATISTICS_DATA]: {
    title: tt(t`Statistics & Data`),
    colour: '#b777E0',
    substrands: []
  },
  [CHANCE_PROBABILITY]: {
    title: tt(t`Chance & Probability`),
    colour: '#FFB100',
    substrands: []
  },
  [GEOMETRY]: {
    title: tt(t`Geometry`),
    colour: '#48AFDB',
    substrands: [
      THREEDSHAPE,
      TWODSHAPE,
      SYMMTRANS_LOCN,
      ANGLE,
      GEOMETRICPROOFS
    ]
  },
  [MEASUREMENT]: {
    title: tt(t`Measurement`),
    colour: '#84C62D',
    substrands: [
      TIME,
      AREA,
      LENGTH,
      MASS,
      VOLUME_CAPACITY,
      TEMPERATURE,
      PYTHAGORAS_TRIG,
      MEAS_GENERAL
    ]
  }
};

export const substrands = {
  [ADDITION_SUBTRACTION]: tt(t`Addition & Subtraction`),
  [MULTIPLICATION_DIVISION]: tt(t`Multiplication & Division`),
  [FRACTIONS]: tt(t`Fractions`),
  [DECIMALS]: tt(t`Decimals`),
  [PERCENTAGES]: tt(t`Percentages`),
  [INDICES]: tt(t`Indices`),
  [OPERATIONS]: tt(t`Operations`),
  [RATES_RATIOS]: tt(t`Rates & Ratios`),
  [NUMBERTHEORY]: tt(t`Number Theory`),
  [MONEY]: tt(t`Money`),
  [WHOLENUMBER]: tt(t`Whole Number`),
  [PATTERNS]: tt(t`Patterns`),
  [EQUATIONS_EXPRESSIONS]: tt(t`Equations & Expressions`),
  [LOGARITHMS]: tt(t`Logarithms`),
  [N_A_GENERAL]: tt(t`General`),
  [THREEDSHAPE]: tt(t`3D Shapes`),
  [TWODSHAPE]: tt(t`2D Shapes`),
  [SYMMTRANS_LOCN]: tt(t`Symmetry, Transformation & Location`),
  [ANGLE]: tt(t`Angles`),
  [GEOMETRICPROOFS]: tt(t`Geometric Proofs`),
  [TIME]: tt(t`Time`),
  [AREA]: tt(t`Area`),
  [LENGTH]: tt(t`Length`),
  [MASS]: tt(t`Mass`),
  [VOLUME_CAPACITY]: tt(t`Volume & Capacity`),
  [TEMPERATURE]: tt(t`Temperature`),
  [PYTHAGORAS_TRIG]: tt(t`Pythagoras & Trigonometry`),
  [MEAS_GENERAL]: tt(t`General`)
};
