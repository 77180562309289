import Resources from './resources';
import { getNextFlags } from './get-next-flags/get-next-flags';
import { getLocale } from './utils';

const getModules = ({ userId, girlId, assignmentId }) => {
  const locale = getLocale();
  const body = {
    assignmentId
  };
  if (locale) {
    body.locale = locale;
  }
  return Resources[userId ? 'getTeacherModules' : 'getStudentModules']
    .post({
      userId,
      girlId
    })
    .withBody(body)
    .send();
};

const saveModules = async ({ userId, moduleName, moduleType, girlId, flags, payload, user, action, assignmentId }) => {
  let from = flags;
  let to = getNextFlags({
    user,
    action,
    flags
  });
  let response;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const body = {
      moduleName,
      moduleType,
      resourceId: girlId,
      assignmentId,
      flags: {
        from,
        to
      },
      content: payload
    };
    const locale = getLocale();
    if (locale) {
      body.locale = locale;
    }
    // eslint-disable-next-line no-await-in-loop
    response = await Resources[userId ? 'saveTeacherModule' : 'saveStudentModule']
      .post({
        userId
      })
      .withBody(body)
      .send();

    if (!(response.status === 200 || response.status === 204)) {
      break;
    }
    if (response.data.retry) {
      from = response.data.meta.flags;
      to = getNextFlags({
        user,
        action,
        flags: from
      });
    } else {
      break;
    }
  }
  return response;
};

const getResourceUrl = async ({ userId, girlId, guid, contentType = 'audio/mpeg', assignmentId }) => {
  const userPrefix = userId ? `/users/${userId}` : '';
  const url = `${window._env_.MLEGateway}${userPrefix}/download-resource-files`;
  const response = await window.fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authtoken: Resources.env.authToken
    },
    body: JSON.stringify({
      guid,
      contentType,
      assignmentId,
      resourceId: girlId
    })
  });
  const blob = await response.blob();
  return window.URL.createObjectURL(blob);
};

const API = {
  getModules,
  saveModules,
  getResourceUrl
};

export default API;
