import _ from 'lodash';

const ducsStateParams = ({
  activityData,
  activityState,
  state = 'review'
}) => {
  const responses = {};

  Object.keys(activityState).forEach((key) => {
    if (key === 'isAttempted' || key === 'isCorrect' || key === 'total') return;
    const { value, type, apiVersion, revision } = _.get(activityState[key], 'user_response') || {};
    responses[key] = {
      value,
      type,
      apiVersion,
      revision,
      responses: _.get(activityState[key], 'user_response.responses', []),
      updatedFormat: true
    };
  });

  const learnosityInitialState = {
    state,
    responses
  };

  const { pageId, locale, layoutVersion, contentVersion } = _.get(activityData, 'meta') || {};

  return {
    pageId: pageId || 1,
    locale: locale || 'en-AU',
    layoutVersion,
    contentVersion,
    learnosityInitialState
  };
};

export default ducsStateParams;
