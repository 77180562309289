exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".button---2kUOd{color:gray;background-color:rgba(0,0,0,0);border:none;outline:none;width:50px;height:50px}.button---2kUOd svg{pointer-events:none}.button---2kUOd:disabled{pointer-events:none;color:#a9a9a9}", ""]);

// exports
exports.locals = {
	"button": "button---2kUOd"
};