exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".widget-header---1ZmKW{display:flex;padding:5px;align-items:center}.widget-header---1ZmKW .widget-select---1vd1_{flex-grow:1;background-color:#fff}.widget-header-text---8jmRy{font-weight:bold;margin-right:10px;color:#545454}", ""]);

// exports
exports.locals = {
	"widget-header": "widget-header---1ZmKW",
	"widget-select": "widget-select---1vd1_",
	"widget-header-text": "widget-header-text---8jmRy"
};