exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".text---2cj0m{display:flex;flex-direction:column;margin-bottom:1px}.text---2cj0m .text-widget---2pW4-{border-radius:6px;border-bottom-right-radius:0;border:none;resize:none;outline:none;padding:10px 20px;color:#000}", ""]);

// exports
exports.locals = {
	"text": "text---2cj0m",
	"text-widget": "text-widget---2pW4-"
};