import { SET_USER_ROLE, SET_USER_TYPE } from './user-actions';
import { userRoles, userTypes } from '../../helpers/constants';

export const initialState = {
  userRole: userRoles.DEFAULT,
  userType: userTypes.DEFAULT
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_ROLE: {
      return {
        ...state,
        userRole: action.userRole.toLowerCase()
      };
    }
    case SET_USER_TYPE: {
      if (action.userType === '') {
        return {
          ...state,
          userType: userTypes.DEFAULT
        };
      }
      return {
        ...state,
        userType: action.userType
      };
    }
    default:
      return state;
  }
};
