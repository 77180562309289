import { combineReducers } from 'redux';
import queryStringReducer from '../mle/query-string-reducer';
import initMleReducer from '../mle/init-mle-reducer';
import activityReducer from '../activity/activity-reducer';
import ducsReducer from '../ducs/ducs-reducer';
import catPageReducer from '../ducs/cat-page-reducer';
import proveletReducer from '../provelet/provelet-reducer';
import widgetMenuReducer from '../widget-menu/widget-menu-reducer';
import modalReducer from '../modal/modal-reducer';
import errorHandlingReducer from '../error-handling/error-handling-reducer';
import widgetEditorReducer from '../widget-editor/widget-editor-reducer';
import userReducer from '../user/user-reducer';
import geogebraReducer from '../widgets/scratchpad/geogebra-reducer';
import detectDevToolsReducer from '../devtools-detect/devtools-detect-reducer';
import spinnerReducer from '../mle/spinner-reducer';
import timeOnlineReducer from '../mle/time-online-reducer';
import conquestReducer from '../conquest/conquest.reducer';

const CombinedReducers = combineReducers({
  queryStringReducer,
  initMleReducer,
  activityReducer,
  ducsReducer,
  catPageReducer,
  proveletReducer,
  widgetMenuReducer,
  modalReducer,
  errorHandlingReducer,
  widgetEditorReducer,
  userReducer,
  geogebraReducer,
  detectDevToolsReducer,
  spinnerReducer,
  timeOnlineReducer,
  conquestReducer
});

export default CombinedReducers;
