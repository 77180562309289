exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".footer---29rdj{height:80px;display:flex;justify-content:space-between;align-items:flex-start;z-index:500;margin:0 20px}.footer---29rdj svg{display:unset}.footer---29rdj .psr-side---2ZCh4{display:flex;align-items:center}.footer---29rdj .psr-side---2ZCh4 .misc-container---38GNw{margin-left:20px;display:flex;align-items:center}.footer---29rdj .psr-side---2ZCh4 .misc-container---38GNw .info-feedback-margin---2g-_Z{margin-left:20px}.footer---29rdj .psr-side---2ZCh4 .btn-label-text---f26iZ{padding-left:10px}.footer---29rdj .psr-side---2ZCh4 .btn-info---3N0BC{background-color:#fff;color:gray;opacity:1}.footer---29rdj .psr-side---2ZCh4 .btn-teacher-notes---Kg_iT{background-color:#3477c7;border-radius:100%;margin-left:20px;padding:0}.footer---29rdj .psr-side---2ZCh4 .btn-teacher-notes---Kg_iT svg{width:30px;height:30px}@media(hover: hover),(-ms-high-contrast: none){.footer---29rdj .psr-side---2ZCh4 .btn-teacher-notes---Kg_iT:hover{background-color:#fd7849}}.footer---29rdj .psr-side---2ZCh4 .btn-teacher-notes---Kg_iT:active{background-color:#b2e1e3}.footer---29rdj.hide---fPHIL{display:none}", ""]);

// exports
exports.locals = {
	"footer": "footer---29rdj",
	"psr-side": "psr-side---2ZCh4",
	"misc-container": "misc-container---38GNw",
	"info-feedback-margin": "info-feedback-margin---2g-_Z",
	"btn-label-text": "btn-label-text---f26iZ",
	"btn-info": "btn-info---3N0BC",
	"btn-teacher-notes": "btn-teacher-notes---Kg_iT",
	"hide": "hide---fPHIL"
};