exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".loading---3Qn_X{width:64px;height:20px;display:flex;justify-content:space-evenly}.ball-loader-ball---2sFKN{will-change:transform;height:20px;width:20px;border-radius:20%;background-color:#fd7849;animation:grow----n0xn .6s ease-in-out infinite alternate}.ball-loader-ball---2sFKN.ball1---3lnnh{transform-origin:100% 50%}.ball-loader-ball---2sFKN.ball2---3kODT{transform:scale(1);animation-delay:.2s}.ball-loader-ball---2sFKN.ball3---3ZJ-w{animation-delay:.4s}@keyframes grow----n0xn{to{transform:scale(0);opacity:0}}", ""]);

// exports
exports.locals = {
	"loading": "loading---3Qn_X",
	"ball-loader-ball": "ball-loader-ball---2sFKN",
	"grow": "grow----n0xn",
	"ball1": "ball1---3lnnh",
	"ball2": "ball2---3kODT",
	"ball3": "ball3---3ZJ-w"
};