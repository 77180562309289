exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".btn-audio---3TWgM{width:50px;height:50px;display:flex;align-items:center;justify-content:center;background-color:#fff;border-radius:50px;border:none;outline:0}@media(hover: hover){.btn-audio---3TWgM:hover .inner---3fReP{background-color:#3477c7}}.btn-audio---3TWgM:active .inner---3fReP{background-color:#b2e1e3}.btn-audio---3TWgM:disabled{opacity:.5;pointer-events:none}.btn-audio---3TWgM .inner---3fReP{width:22px;height:22px;background-color:#fd7849}.btn-audio---3TWgM.record---26uP4 .inner---3fReP{border-radius:30px}", ""]);

// exports
exports.locals = {
	"btn-audio": "btn-audio---3TWgM",
	"inner": "inner---3fReP",
	"record": "record---26uP4"
};