import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { BtnText } from '../buttons';
import css from './image-modal.scss';

const ImageModal = ({ url, handleCancel }) => {
  const [dimensions, setDimensions] = useState(null);
  const [hasError, setHasError] = useState(false);
  let aspectRatio;
  const handleResize = () => {
    if (!aspectRatio) return;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const widthFromMaxHeight = aspectRatio * windowHeight;
    const heightFromMaxWidth = windowWidth / aspectRatio;
    let width;
    let height;
    if (widthFromMaxHeight <= windowWidth) {
      width = widthFromMaxHeight;
      height = widthFromMaxHeight / aspectRatio;
    } else {
      width = heightFromMaxWidth * aspectRatio;
      height = heightFromMaxWidth;
    }
    setDimensions({
      width: Math.max(0, width - 100),
      height: Math.max(0, height - 150)
    });
  };
  const handleLoad = (e) => {
    const image = e.target;
    aspectRatio = image.naturalWidth / image.naturalHeight;
    handleResize();
  };
  const handleError = () => {
    setHasError(true);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
    return () => window.removeEventListener('resize', handleResize, false);
  }, []);
  return (
    <div className={css['image-modal']}>
      {hasError ?
        <Trans>Could not load image</Trans> :
        <img src={url} alt="" onLoad={handleLoad} onError={handleError} {...dimensions} />
      }
      {(dimensions || hasError) && <BtnText
        className={css.button}
        callBack={handleCancel}
      >
        <Trans>Ok</Trans>
      </BtnText>}
    </div>
  );
};

ImageModal.propTypes = {
  url: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired
};

export default ImageModal;
