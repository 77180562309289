import React from 'react';
import { Trans } from '@lingui/macro';
import css from './no-internet.scss';

export const NoInternet = () => (
  <div className={css['no-internet']}>
    <h1 className={css.head}><Trans>Network error!</Trans></h1>
    <h2 className={css.subhead}><Trans>It looks like you have lost your internet connection.</Trans></h2>
    <p className={css.para}><Trans>Please check your internet while we try to reconnect you.</Trans></p>
  </div>
);
